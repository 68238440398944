import { Button, Col, Form, Typography, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { personalDetailsFormFields } from "../constants/personalDetailsFormFields";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { setCities, setStates } from "../store/slices/lookupSlice";
import AjaXpress from "../helperFunctions/api";
import { updatePersonalData } from "../store/slices/personalDetailSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CaretLeftOutlined } from "@ant-design/icons";
import NavBar from "../genericComponents/CustomNav";
import { CustomSider } from "../genericComponents/CustomSider";
import { LifeStyleFormFields } from "../constants/LifeStyleDetailsFormFields";
import {
  editProfilePersonalDetails,
  getPersonalDetails,
} from "../helperFunctions/endpoints";
import { getLookupfromLocal } from "../utils/utils";

const LifeStyle = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(LifeStyleFormFields);
  const [lookupData, setLookupData] = useState({});
  const countries = useSelector((state) => state.lookup.countries);
  const states = useSelector((state) => state.lookup.states);
  const cities = useSelector((state) => state.lookup.cities);
  const [lifeStyleDetails, setLifeStyleDetails] = useState({});
  const [loading, setLoading] = useState({ get: false, set: false });
  const { Header, Content, Footer } = Layout;

  const getLifestyleDetails = async () => {
    setLoading((loading) => {
      return { ...loading, get: true };
    });
    try {
      const response = await getPersonalDetails();
      if (response?.code === "CH200") {
        let data = response?.data?.lifeStyleData;
        setLifeStyleDetails(data);
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, get: false };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const lookupData = JSON.parse(localStorage.getItem("lookupData"));
    setLookupData(lookupData);
    getLifestyleDetails();
    if (Object.keys(lookupData).length) {
      let data = [...fields];
      //console.log(Object.keys(lookupData));
      data = fields.map((field) => {
        return {
          ...field,
          defaultValueGetter: lifeStyleDetails,
          options: lookupData[field?.lookups] || [],
        };
      });
      setFields(data);
    }
  }, []);

  const onFormChangeHandler = async (field) => {
    try {
      if (field.hasOwnProperty("country")) {
        getStateLookups(field.country);
      }
      if (field.hasOwnProperty("state")) {
        getCityLookus(field.state);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getStateLookups = async (country) => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `lookup/getStateLookup/${country}`,
      });
      if (response?.response?.status == 200) {
        dispatch(setStates(response?.data[0]?.states));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getCityLookus = async (state) => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `lookup/getCityLookup/${state}`,
      });
      if (response?.response?.status == 200) {
        dispatch(setCities(response?.data[0]?.cities));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const addPersonalDetails = async (values) => {
    setLoading((loading) => {
      return { ...loading, set: true };
    });
    try {
      values.section = "lifestyle";
      const response = await editProfilePersonalDetails(values);
      if (response?.status === 200) {
        toast.success(response.message);
        navigate("/myprofile");
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, set: false };
    });
  };
  const handleNext = (values) => {
    addPersonalDetails(values);
  };

  const handleFormChanges = (name, value) => {
    //console.log(name, value);
    form.setFieldValue(name, value);
  };

  const handlePrev = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userDetails");
    navigate("/registration");
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <div className="login-margin" style={{ marginBottom: "3rem" }}>
                <div className="sub-title">Life Style </div>

                <Form
                  layout="vertical"
                  name="loginForm"
                  form={form}
                  onFinish={handleNext}
                  onValuesChange={onFormChangeHandler}
                >
                  {fields?.map((Field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Item
                          label={Field.label}
                          name={Field.name}
                          rules={Field.rules}
                          style={Field.style ? Field.style : {}}
                          {...(Field.feedBack && {
                            hasFeedback: Field.feedBack,
                          })}
                          {...(Field.valuePropName && {
                            valuePropName: Field.valuePropName,
                          })}
                        >
                          {formFieldRenderer(Field, handleFormChanges)}
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="border-Radius-5px block-button-text height-100p"
                      loading={loading.set}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LifeStyle;
