import React, { useState } from "react";
import { Form, Input, Button, Col, Row, Modal } from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Logo from "../images/logo.jpeg";
import MobileNumberInput from "../genericComponents/MobileNumberInput";
import { countryCodesWithIcons } from "../constants/countrycodes";
import {
  getforgotpassword,
  postforgototpsubmit,
  postupdateforgotpassword,
} from "../helperFunctions/endpoints";
import { useNavigate } from "react-router-dom";
import OtpModal from "../genericComponents/OtpModal";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const handleForgotPassword = async (values) => {
    try {
      const { countryCode, phoneNumber } = values;
      setPhoneNumber(`${phoneNumber}`);
      const response = await getforgotpassword(phoneNumber);
      //console.log(response);
      setShowOtpModal(true);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
  };

  const verifyOTP = async () => {
    setVerifyLoading(true);
    try {
      const response = await postforgototpsubmit({
        phoneNumber,
        otp,
      });
      if (response?.code === "CH400") {
        toast.error(response?.err);
      }
      setShowOtpModal(false);
      //console.log(response);
      localStorage.setItem("access_token", response?.token);

      showModal1();
    } catch (error) {
      //console.log(error);
    }
    setVerifyLoading(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = async () => {
    setIsModalOpen1(false);

    const response = await postupdateforgotpassword({
      password,
    });
    if (response?.code === "CH400") {
      toast.error(response?.err);
    } else {
      toast.success(response?.message);
      localStorage.removeItem("access_token");
      localStorage.removeItem("userDetails");
      navigate("/");
    }
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  return (
    <Row type="flex" align="middle">
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        <div
          className="login-image"
          style={{
            display: "block",
            backgroundImage:
              "url(https://d3nn873nee648n.cloudfront.net/1200x1800-new/20354/SM1015626.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <div className="login-margin">
          <Title level={3} className="title">
            <img
              key="rootloader"
              src={Logo}
              alt="no_image"
              className="login-logo"
            />
            Connecting Hearts
          </Title>
          <div>
            <Title level={4} className="title">
              Forgot Password
            </Title>
          </div>
          <Form
            layout="vertical"
            name="loginForm"
            form={form}
            onFinish={handleForgotPassword}
            initialValues={{
              countryCode: "+91",
            }}
          >
            <Form.Item
              label={"Mobile Number"}
              name={"phoneNumber"}
              type="number"
              rules={[
                {
                  required: true,
                  message: "Please input your Mobile Number!",
                },
              ]}
            >
              <MobileNumberInput
                name={"phoneNumber"}
                countryCodeOptions={countryCodesWithIcons?.map((i) => {
                  return {
                    label: `${i.emoji} ${i.value}`,
                    value: i.value,
                  };
                })}
                onChangeHandler={handleFormChanges}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
              >
                Send OTP
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="text"
            block
            className="border-Radius-5px block-button-text height-100p back-button"
            onClick={() => navigate("/")}
          >
            <CaretLeftOutlined />
            Back
          </Button>
        </div>
      </Col>
      <OtpModal
        otp={otp}
        setOtp={setOtp}
        isModalOpen={showOtpModal}
        confirmOTP={verifyOTP}
        loading={verifyLoading}
        subTitle={"Check your Phone and Fill the OTP"}
      />
      <Modal title="Set New Password" open={isModalOpen1} footer={null}>
        <Form>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var re =
                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                  if (!value || re.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      " At least One capital letter, one small letter one number one special character and minimum 8 characters"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className="border-Radius-5px"
              placeholder={"New Password"}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              className="border-Radius-5px"
              placeholder={"Confirm Password"}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              onClick={handleOk1}
              className="border-Radius-5px block-button-text height-100p"
            >
              Ok
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default ForgotPassword;
