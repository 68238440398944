import { setCountries, setLookup } from "../store/slices/lookupSlice";
import {
  setDescription,
  setProfilePicDetails,
  setUserId,
} from "../store/slices/userSlice";
import AjaXpress from "./api";

export const setUserDetailsInLocalStorage = async (data) => {
  localStorage.setItem("userDetails", JSON.stringify(data));
};
export const getUserDetailsFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("userDetails"));
};

export const getLookupDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("lookupData"));
};
export const validateToken = async ({ navigate, dispatch }) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "auth/validateToken",
    });
    if (response?.response?.status === 200) {
      await getUserDetails();
      await getLookups({ dispatch });
      await getCountryLookups({ dispatch });
    } else {
      navigate("/");
    }
  } catch (error) {
    //console.log(error);
  }
};
export const getLookups = async ({ dispatch }) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "lookup",
    });
    if (response?.response?.status == 200) {
      localStorage.setItem(
        "lookupData",
        JSON.stringify(response?.data?.lookupData[0])
      );
      dispatch(setLookup(response?.data?.lookupData[0]));
    }
  } catch (error) {
    //console.log(error);
  }
};
export const getUserDetails = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      body: {},
      slug: "auth/getUser",
    });
    if (response?.response?.status == 200) {
      setUserDetailsInLocalStorage(response?.data?.data);
      return response?.data;
    }
  } catch (error) {
    //console.log(error);
  }
};
export const getCountryLookups = async ({ dispatch }) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "lookup/getCountryLookup",
    });
    if (response?.response?.status == 200) {
      dispatch(setCountries(response?.data));
    }
  } catch (error) {
    //console.log(error);
  }
};
export const generateOtp = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: {
        phoneNumber: reqPaylaod?.phoneNumber,
        extension: reqPaylaod?.countryCode,
      },
      slug: "auth/generateOtp",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Personal Details APIs(get)
export const getPersonalDetails = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "personalDetails/getUserProfileData/",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const editProfilePersonalDetails = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "personalDetails/editProfile",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getUserDetailsPersonalDetails = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "personalDetails/getUserProfileData",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getDetailsView = async (target_user_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/getDetailView/${target_user_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//SRCM APIs(get)
export const getsrcmDetails = async (filename) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `getsrcmDetails/file/${filename}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getcrmdetails = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "crmdetails",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
// Profile APIs(get)

export const getProfilePic = async (userId, fileId) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `profile/file/${userId}/${fileId}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Family details APIs(get)
export const getupdatingfamilydetails = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "family",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Payment APIs(get)
export const getMembershipList = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMembershipList",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getMyUnlockedProfile = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyUnlockedProfiles",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getbuyMembership = async (membership_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/buyMembership/${membership_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getunlockProfile = async (target_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/unlockProfile/${target_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getverifyPayment = async (order_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/verifyPayment/${order_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//admin api (get)
export const getallUser = async (type) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `chAdmin/getallUser/${type}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getverifyUser = async (order_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `chAdmin/verifyUser/${order_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const gettriggerNotification = async (order_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "chAdmin/gettriggerNotification/",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

// Partner preference APIs(get)
export const getpartnerpreference = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "preference",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Lookup APIs(get)
export const getlookupdata = async (type) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `lookup?type/${type}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getCountryLookup = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `lookup/getcountrylookup`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getstatelookupdata = async (type, country_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `lookup/getstatelookup/${type}/${country_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getcitylookupdata = async (state_id, type) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `lookup/getcitylookup/${state_id}/${type}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Dashboard APIs(get)
export const getintrestreceive = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "intrest/getintrests",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getviewing = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyProfileView",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getprofiledeclined = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyDeclinedProfiles",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getusersdeclinedmyintrest = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getUsersWhoHaveDeclinedMe",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getuserssentintrest = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyInterestedProfiles",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getacceptanceprofiles = async (type) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/getAcceptanceProfiles/${type}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getsliderpics = async (name) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/getSliderPics/${name}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getjustjoined = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getJustJoined",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getallprofiles = async (endpoint) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: endpoint,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getvisitprofile = async (user_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/visitProfile/${user_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getprofilevisitors = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getProfileVisitors",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getshortlist = async (user_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/shortlist/${user_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getunshortlist = async (user_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/unshortlist/${user_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getmyshortlistedprofiles = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyShortlistedProfiles",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getignoreprofile = async (user_id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/ignoreProfile/${user_id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getallignoredprofile = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getAllIgnoredProfiles",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getdailyrecommendations = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "interest/getDailyRecommendations",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const deleteProfile = async (body) => {
  try {
    let response = await AjaXpress({
      method: "DELETE",
      slug: "auth/deleteProfile",
      body: body,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getInterestsRecv = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "interest/getInterests",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getsearchbyprofileid = async (heartsId) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `auth/searchByProfileID/${heartsId}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getblockprofile = async (id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/blockProfile/${id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getmyblockedprofile = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyBlockedProfiles",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getunblockprofile = async (id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/unblockprofile/${id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getunignoreprofile = async (id) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `dashboard/unIgnoreProfile/${id}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getmymembershipdetails = async () => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: "dashboard/getMyMembershipDetails",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//SRCM APIs(post)
export const uploadsrcmId = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "srcmDetails/uploadsrcmId",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//dashboard api(post)
export const unsendInterest = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: JSON.stringify(reqPaylaod),
      slug: "interest/unsendInterest",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const sendInterest = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: JSON.stringify(reqPaylaod),
      slug: "interest/sendInterest",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const updateInterest = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "interest/updateInterest",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const searchProfile = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "dashboard/searchProfile",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const submitReview = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "dashboard/submitReview",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const updateNotificationCount = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "dashboard/updateNotificationCount",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//profile(post)
export const uploadProfilePic = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "profile/uploadProfilePic",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const updateProfilePic = async (picName, id, primary = false) => {
  let body = {
    s3Link: picName,
    id: id,
    primary: primary,
  };
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: body,
      slug: "profile",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const deleteProfilePic = async (fileId) => {
  try {
    let response = await AjaXpress({
      method: "DELETE",
      slug: `profile/deleteProfilePic/${fileId}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Personal Details APIs(patch)
export const updatePersonalDetails = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "personalDetails",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const patchPersonalDetails = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "personalDetails/editProfile",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//auth (patch)
export const updateLastActivityScreen = async (screenName) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      slug: `auth/updateLastActivityScreen/${screenName}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const patchchangePassword = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "auth/changePassword",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const getforgotpassword = async (phoneNumber) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `auth/forgetPassword/${phoneNumber}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const postforgototpsubmit = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "auth/verifyForgottenOTP",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const postupdateforgotpassword = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "POST",
      body: reqPaylaod,
      slug: "auth/updateForgottenPassword",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//scrms (patch)
export const patchsrcmDetails = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "srcmDetails/updateSrcmDetails",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//profile(patch)
export const patchprofile = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "profile",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//family(patch)
export const patchfamily = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "family",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
//Partner preference APIs(patch)
export const patchpreference = async (reqPaylaod) => {
  try {
    let response = await AjaXpress({
      method: "PATCH",
      body: reqPaylaod,
      slug: "preference",
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

export const updateLastScreenActivity = async (screen) => {
  try {
    AjaXpress({
      method: "PATCH",
      body: {},
      slug: `auth/updateLastActiveScreen/${screen}`,
    });
  } catch (error) {
    //console.log(error);
  }
};

export const getStatelookup = async (country) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `lookup/getStateLookup/${country}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
export const getCitieslookup = async (state) => {
  try {
    let response = await AjaXpress({
      method: "GET",
      slug: `lookup/getCityLookup/${state}`,
    });
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};
