import React, { useEffect, useState } from "react";

import {
  Button,
  Layout,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  Col,
  Row,
  Divider,
  TreeSelect,
} from "antd";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Slider } from "antd";

import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getsearchbyprofileid,
  searchProfile,
} from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
import NavBar from "../genericComponents/CustomNav";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { CustomSider } from "../genericComponents/CustomSider";
const { Content } = Layout;
export const SearchPage = () => {
  const navigate = useNavigate();

  const lookupData = useSelector((state) => state.lookup.lookupData);
  const countries = useSelector((state) => state.lookup.countries);

  const [searchProfileID, setSearchProfileID] = useState("HEARTS");

  const [form] = Form.useForm();

  useEffect(() => {
    //console.log(lookupData);
  }, [lookupData]);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const submit = async (values) => {
    //console.log(values);
    if (
      !values.country?.length &&
      !values.religion?.length &&
      !values.motherTongue?.length &&
      !values.maritalStatus?.length &&
      !values.age.min &&
      !values.age.max &&
      !values.height.min &&
      !values.height.max &&
      !values.income.min &&
      !values.income.max &&
      !values.age.min &&
      !values.age.max &&
      !values.preference
    ) {
      toast.error("Please fill atleast one field");
      return;
    }

    //console.log(values);
    const searchResponse = await searchProfile(values);
    if (searchResponse?.code === "CH200") {
      //console.log(searchResponse);
      if (searchResponse?.filteredProfiles?.length > 0) {
        navigate("/allprofiles", {
          state: {
            title: "Search Results",
            data: searchResponse?.filteredProfiles,
          },
        });
      } else {
        toast.error("No Profiles Found, Please try different search criteria");
      }
    }
  };

  const onSearch = async () => {
    const profileId = searchProfileID.replace("HEARTS", "");
    const searchProfile = await getsearchbyprofileid(profileId);
    if (searchProfile?.code === "CH200" && searchProfile?.filteredProfile) {
      navigate("/profileview/" + searchProfile?.filteredProfile?.clientID);
    } else {
      toast.error(searchProfile?.err || "Something went wrong");
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content 
          className="content-wrapper" 
          style={{ 
            padding: '24px'
          }}
        >
          <Row gutter={[24, 24]} justify="center">
            <Col xs={24} sm={24} md={20} lg={18} xl={16}>
              <div style={{ 
                background: '#fff', 
                padding: '24px', 
                borderRadius: '8px', 
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)' 
              }}>
                <h2 style={{ marginBottom: '20px' }}>Search By Profile ID</h2>
                <Input
                  style={{
                    marginTop: 20,
                  }}
                  size="medium"
                  label="Search By Profile Id"
                  placeholder={"HEARTSXXXX"}
                  name="searchByProfileId"
                  value={searchProfileID}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value.startsWith("HEARTS")) {
                      value = "HEARTS";
                    }
                    setSearchProfileID(value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "_") {
                      e.preventDefault();
                    }
                    if (e.key === "Backspace" && searchProfileID === "HEARTS") {
                      e.preventDefault();
                    }
                  }}
                />
                <Button
                  style={{
                    marginTop: 20,
                  }}
                  block
                  type="primary"
                  onClick={() => onSearch()}
                >
                  Search
                </Button>
              </div>
              
              <Divider />

              <div style={{ 
                background: '#fff', 
                padding: '24px', 
                borderRadius: '8px', 
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)' 
              }}>
                <h2 style={{ marginBottom: '24px' }}>Advanced Search</h2>
                <Form
                  form={form}
                  name="search"
                  onFinish={submit}
                  layout="vertical"
                  style={{
                    width: '100%',
                  }}
                >
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label="Country" name="country">
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder={"Country"}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {countries?.map((element) => {
                            return (
                              <Select.Option value={element.value}>
                                {element.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label="Religion" name="religion">
                        <Select
                          mode="multiple"
                          placeholder={"Religion"}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {lookupData?.religion?.map((element) => {
                            return (
                              <Select.Option value={element.value}>
                                {element.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label="Mother Tongue" name="motherTongue">
                        <Select
                          mode="multiple"
                          placeholder={"Mother Tongue"}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {lookupData?.motherTongue?.map((element) => {
                            return (
                              <Select.Option value={element.value}>
                                {element.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label="Marital Status" name="maritalStatus">
                        <Select
                          mode="multiple"
                          placeholder={"Merital Status"}
                          allowClear
                        >
                          {lookupData?.maritalStatus?.map((element) => {
                            return (
                              <Select.Option value={element.value}>
                                {element.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item 
                            label="Min Age" 
                            name={["age", "min"]}
                            style={{ marginBottom: 24 }}
                          >
                            <Select
                              placeholder={"Min Age"}
                              allowClear
                              style={{ width: '100%' }}
                            >
                              {lookupData?.age?.map((element) => {
                                return (
                                  <Select.Option value={element.value}>
                                    {element.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item 
                            label="Max Age" 
                            name={["age", "max"]}
                            style={{ marginBottom: 24 }}
                          >
                            <Select
                              placeholder={"Max Age"}
                              allowClear
                              style={{ width: '100%' }}
                            >
                              {lookupData?.age?.map((element) => {
                                return (
                                  <Select.Option value={element.value}>
                                    {element.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item 
                            label="Min Height" 
                            name={["height", "min"]}
                            style={{ marginBottom: 24 }}
                          >
                            <Select
                              placeholder={"Min Height"}
                              allowClear
                              style={{ width: '100%' }}
                            >
                              {lookupData?.height?.map((element) => {
                                return (
                                  <Select.Option value={element.value}>
                                    {element.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item 
                            label="Max Height" 
                            name={["height", "max"]}
                            style={{ marginBottom: 24 }}
                          >
                            <Select
                              placeholder={"Max Height"}
                              allowClear
                              style={{ width: '100%' }}
                            >
                              {lookupData?.height?.map((element) => {
                                return (
                                  <Select.Option value={element.value}>
                                    {element.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item 
                            label="Min Income" 
                            name={["income", "min"]}
                            style={{ marginBottom: 24 }}
                          >
                            <Select
                              placeholder={"Min Income"}
                              allowClear
                              style={{ width: '100%' }}
                            >
                              {lookupData?.income?.map((element) => {
                                return (
                                  <Select.Option value={element.value}>
                                    {element.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item 
                            label="Max Income" 
                            name={["income", "max"]}
                            style={{ marginBottom: 24 }}
                          >
                            <Select
                              placeholder={"Max Income"}
                              allowClear
                              style={{ width: '100%' }}
                            >
                              {lookupData?.income?.map((element) => {
                                return (
                                  <Select.Option value={element.value}>
                                    {element.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Form.Item style={{ marginTop: '24px' }}>
                    <Row gutter={[16, 16]} justify="end">
                      <Col xs={24} sm={12} md={6}>
                        <Button 
                          type="default" 
                          size="large" 
                          block
                          onClick={() => {
                            form.resetFields();
                          }}
                        >
                          Clear All
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                        <Button type="primary" htmlType="submit" size="large" block>
                          Search Profiles
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};
