import { Button, Col, Form, Typography, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { familyDetailsFormFields } from "../constants/familyDetailsFormFields";
import { useNavigate, useLocation } from "react-router-dom";
import { updateFamilyDetails } from "../store/slices/familyDetailSlice";
import AjaXpress from "../helperFunctions/api";
import { toast } from "react-toastify";
import { CaretLeftOutlined } from "@ant-design/icons";
import { CustomSider } from "../genericComponents/CustomSider";
import NavBar from "../genericComponents/CustomNav";
import { updateLastScreenActivity } from "../helperFunctions/endpoints";

const FamilyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(familyDetailsFormFields);
  const [loading, setLoading] = useState({ get: false, set: false });
  const lookupData = useSelector((state) => state.lookup.lookupData);
  const familyDetails = useSelector((state) => state.familyDetails);
  const countries = useSelector((state) => state.lookup.countries);
  let { id } = useParams();

  const getFamilyDetails = async () => {
    setLoading((loading) => {
      return { ...loading, get: true };
    });
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `family`,
      });
      if (response?.response?.status == 200) {
        let data = response.data.data;
        let obj = {
          familyStatus: data?.familyStatus,
          familyValues: data?.familyValues,
          familyType: data?.familyType,
          familyIncome: data?.familyIncome,
          fatherOccupation: data?.fatherOccupation,
          motherOccupation: data?.motherOccupation,
          brothers: data?.brothers ? Number(data?.brothers) : null,
          marriedBrothers: data?.marriedBrothers
            ? Number(data?.marriedBrothers)
            : null,
          sisters: data?.sisters ? Number(data?.sisters) : null,
          marriedSisters: data?.marriedSisters
            ? Number(data?.marriedSisters)
            : null,
          gothra: data?.gothra,
          livingWith: data?.livingWithParents,
          familyBased: data?.familyBasedOutOf,
        };
        Object.keys(obj)?.forEach((item) => {
          handleFormChanges(item, obj[item]);
        });
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, get: false };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getFamilyDetails();
    updateLastScreenActivity("familydetails");
  }, []);

  useEffect(() => {
    if (Object.keys(lookupData).length) {
      let data = [...familyDetailsFormFields].filter((i) => {
        if (
          i.name === "marriedBrothers" &&
          (familyDetails.brothers === 1 || !familyDetails.brothers)
        )
          return false;
        if (
          i.name === "marriedSisters" &&
          (familyDetails.sisters === 1 || !familyDetails.sisters)
        )
          return false;
        return true;
      });

      data = data?.map((field) => {
        return {
          ...field,
          defaultValueGetter: familyDetails,
          ...(field?.apiLookups === "country" && {
            options: countries,
          }),
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      setFields(data);
    }
  }, [lookupData, familyDetails, countries]);

  const addFamilyDetails = async () => {
    setLoading((loading) => {
      return { ...loading, set: true };
    });
    try {
      let response = await AjaXpress({
        method: "PATCH",
        body: {
          familyStatus: familyDetails.familyStatus,
          familyValues: familyDetails.familyValues,
          familyType: familyDetails.familyType,
          familyIncome: familyDetails.familyIncome,
          fatherOccupation: familyDetails.fatherOccupation,
          motherOccupation: familyDetails.motherOccupation,
          brothers: familyDetails.brothers,
          marriedBrothers: familyDetails.marriedBrothers,
          sisters: familyDetails.sisters,
          marriedSisters: familyDetails.marriedSisters,
          gothra: familyDetails.gothra,
          familyBasedOutOf: familyDetails.familyBased,
          livingWithParents: familyDetails.livingWith,
        },
        slug: "family",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);

        if (id) {
          navigate("/myprofile");
        } else {
          navigate("/partnerpreference");
        }
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, set: false };
    });
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
    dispatch(
      updateFamilyDetails({
        key: name,
        value: value,
      })
    );
  };
  const handleNext = (values) => {
    addFamilyDetails();
  };

  const { state } = useLocation();
  const { Text } = Typography;
  const { Header, Content, Footer } = Layout;

  return (
    <Layout>
      {!!id && <NavBar navigate={navigate} />}
      <Layout>
        {!!id && <CustomSider navigate={navigate} />}
        <Content className="content-wrapper">
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={16} xl={12}>
              <div className="login-margin" style={{ marginBottom: "3rem" }}>
                {!id && <Progressbar percent={50} />}
                <div className="sub-title">Family Details</div>
                <div style={{ padding: "1rem" }}>
                  Provide additional information about your Family
                </div>
                <Form
                  layout="vertical"
                  name="loginForm"
                  form={form}
                  onFinish={handleNext}
                  initialValues={{
                    familyStatus: familyDetails.familyStatus,
                    familyValues: familyDetails.familyValues,
                    familyType: familyDetails.familyType,
                    familyIncome: familyDetails.familyIncome,
                    fatherOccupation: familyDetails.fatherOccupation,
                    motherOccupation: familyDetails.motherOccupation,
                    brothers: familyDetails.brothers,
                    marriedBrothers: familyDetails.marriedBrothers,
                    sisters: familyDetails.sisters,
                    marriedSisters: familyDetails.marriedSisters,
                    gothra: familyDetails.gothra,
                    livingWith: familyDetails.livingWith,
                    familyBased: familyDetails.familyBased,
                  }}
                >
                  {fields?.map((Field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Item
                          label={Field.label}
                          name={Field.name}
                          rules={Field.rules}
                          style={Field.style ? Field.style : {}}
                          {...(Field.feedBack && {
                            hasFeedback: Field.feedBack,
                          })}
                          {...(Field.valuePropName && {
                            valuePropName: Field.valuePropName,
                          })}
                        >
                          {formFieldRenderer(Field, handleFormChanges)}
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="border-Radius-5px block-button-text height-100p"
                      loading={loading.set}
                    >
                      {id ? "Update" : "Next"}
                    </Button>
                  </Form.Item>
                </Form>
                {!id && (
                  <Button
                    type="text"
                    block
                    className="border-Radius-5px block-button-text height-100p back-button"
                    onClick={() => navigate("/srcmform")}
                  >
                    <CaretLeftOutlined />
                    Back
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default FamilyDetails;
