import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useEffect } from "react";

const { Text } = Typography;
const { Search } = Input;

const DropdownInput = ({ name, label, onSelect, data, defaultValueGetter }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedName, setSelectedName] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(data);

  const showLoading = () => {
    //console.log("clicked");
    setOpen(true);
  };

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setSelectedValue(e.target.value);
    const selectedObj = data.filter((item) => item.value === e.target.value);
    setSelectedName(selectedObj[0]?.label);
    onSelect(e.target.value);
  };

  useEffect(() => {
    setFilteredData(data);
    onSearch(searchQuery);
    if (defaultValueGetter?.hasOwnProperty(name) && data) {
      setSelectedValue(defaultValueGetter[name]);
      const selectedObj = data?.filter(
        (item) => item.value === defaultValueGetter[name]
      );
      setSelectedName(selectedObj[0]?.label);
    }
  }, [data, defaultValueGetter]);

  const onSearch = (value) => {
    //console.log("search:", value);
    if (data)
      setFilteredData(
        data.filter((item) =>
          item.label.toLowerCase().includes(value.toLowerCase())
        )
      );
    setSearchQuery(value);
  };

  const hideModal = () => {
    //console.log("clicked");
    setOpen(false);
  };

  return (
    <>
      <Space
        direction="vertical"
        onClick={showLoading}
        style={{ width: "100%" }}
      >
        <Text
          style={{
            position: "relative",
            display: "inline-flex",
            width: "100%",
            padding: "7px 11px",
            fontSize: "16px",
            lineHeight: "1.5",
            borderRadius: "5px",
            color: selectedValue ? "#000000e0" : "#00000040",
            backgroundColor: "#0000000a",
            borderColor: "#d9d9d9",
            boxShadow: "none",
            borderWidth: "1px",
            borderStyle: "solid",
          }}
        >
          {selectedName || label}
        </Text>
      </Space>
      <Modal
        title={<p>{label}</p>}
        open={open}
        closable={false}
        onOk={hideModal}
        onCancel={hideModal}
      >
        <Input
          placeholder="Search"
          allowClear
          onChange={(e) => onSearch(e.target.value)}
          size="large"
          className="border-Radius-5px"
          style={{ marginBottom: "10px" }}
        />
        <Radio.Group
          name={name}
          onChange={onChange}
          value={selectedValue}
          style={{
            maxHeight: "50vh",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Space direction="vertical">
            {filteredData?.map((item) => (
              <Radio
                style={{ padding: "5px", fontSize: "16px" }}
                value={item?.value}
              >
                {item?.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};
export default DropdownInput;
