import React, { useEffect, useState } from "react";
import { Col, Layout, Pagination, Row, Typography, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getallprofiles,
  getignoreprofile,
  getshortlist,
  getunignoreprofile,
  getunshortlist,
  sendInterest,
  unsendInterest,
  updateInterest,
} from "../helperFunctions/endpoints";
import {
  AllProfileUserCard,
  UserLargeListProfileCard,
} from "../genericComponents/UserProfileCard";
import { toast } from "react-toastify";
import { ProfileNavScreen } from "../utils/utils";
import NavBar from "../genericComponents/CustomNav";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Header, Content, Footer } = Layout;

export const AllprofilesPage = () => {
  const { Text } = Typography;
  const [allProfilesData, setAllProfilesData] = useState([]);
  const [page, setPage] = useState(1);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [isVisibleInterest, setIsVisibleInterest] = useState(true);
  const [isVisibleShortlist, setIsVisibleShortlist] = useState(true);
  const [isVisibleIgnore, setIsVisibleIgnore] = useState(true);
  const [isVisibleUnInterest, setIsVisibleUnSendInterest] = useState(false);
  const [isVisibleUpdateInterest, setIsVisibleUpdateInterest] = useState(false);
  const [isVisibleUnShortlist, setIsVisibleUnShortlist] = useState(false);
  const [isVisibleUnIgnore, setIsVisibleUnIgnore] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    getProfiles(state);
  }, [state]);

  const getProfiles = (state) => {
    const endpoint = "dashboard/getAllProfiles";
    const url = endpoint || "dashboard/getAllProfiles";
    if (state) {
      setIsVisibleIgnore(false);
      setIsVisibleShortlist(false);
      setIsVisibleInterest(false);

      if (state.screenCode === ProfileNavScreen.InterestReceivedScreen) {
        setIsVisibleUpdateInterest(true);
      }

      if (state.screenCode === ProfileNavScreen.ShortlistScreen) {
        setIsVisibleUnShortlist(true);
        setIsVisibleInterest(true);
      }

      if (state.screenCode === ProfileNavScreen.InterestSentScreen) {
        setIsVisibleUnSendInterest(true);
      }
      if (state.screenCode === ProfileNavScreen.IgnoredScreen) {
        setIsVisibleUnIgnore(true);
      }
      if (state.screenCode === ProfileNavScreen.UnlockedScreen) {
        setIsVisibleIgnore(true);
        setIsVisibleShortlist(true);
        setIsVisibleInterest(true);
      }
      setAllProfilesData(state?.data);
      setFilteredProfiles(state?.data?.splice(0, 10));
    } else {
      setIsVisibleUnSendInterest(false);
      setIsVisibleUnShortlist(false);
      setIsVisibleUnIgnore(false);
      getallprofiles(url).then((res) => {
        //console.log(res);
        if (res?.code === "CH200") {
          setAllProfilesData(res?.filteredProfiles);
          setFilteredProfiles(res?.filteredProfiles?.splice(0, 13));
        }
      });
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilteredProfiles(allProfilesData?.splice((page - 1) * 10, 10));
  };

  const interestClick = (profileItem) => {
    if (profileItem?.isInterestSent) {
      unsendInterest({ receiver_id: profileItem?.clientID }).then((res) => {
        if (res?.code === "CH200") {
          profileItem.isInterestSent = false;
          setFilteredProfiles((prevState) => [...prevState, profileItem]);
          toast.success(res?.message);
        }
      });
    } else {
      sendInterest({ targetId: profileItem?.clientID }).then((res) => {
        if (res?.code === "CH200") {
          profileItem.isInterestSent = true;
          setFilteredProfiles((prevState) => [...prevState, profileItem]);
          toast.success(res?.message);
        }
      });
    }
  };

  const shortlistClick = (profileItem) => {
    if (profileItem?.isShortlisted) {
      getunshortlist(profileItem?.clientID).then((res) => {
        if (res?.code === "CH200") {
          profileItem.isShortlisted = false;
          setFilteredProfiles((prevState) => [...prevState, profileItem]);
          toast.success(res?.message);
        }
      });
    } else {
      getshortlist(profileItem?.clientID).then((res) => {
        if (res?.code === "CH200") {
          profileItem.isShortlisted = true;
          setFilteredProfiles((prevState) => [...prevState, profileItem]);
          toast.success(res?.message);
        }
      });
    }
  };
  const updateInterestClick = (profileItem, status) => {
    const reqbody = {
      _id: profileItem?.clientID,
      status: status,
    };
    updateInterest(reqbody).then((res) => {
      if (res?.code === "CH200") {
        toast.success(res?.message);
      }
    });
  };

  const ignoreClick = (profileItem) => {
    if (profileItem?.isIgnored) {
      getunignoreprofile(profileItem?.clientID).then((res) => {
        if (res?.code === "CH200") {
          profileItem.isIgnored = false;
          setFilteredProfiles((prevState) => [...prevState, profileItem]);
          toast.success(res?.message);
        }
      });
    } else {
      getignoreprofile(profileItem?.clientID).then((res) => {
        if (res?.code === "CH200") {
          profileItem.isIgnored = true;
          setFilteredProfiles((prevState) => [...prevState, profileItem]);
          toast.success(res?.message);
        }
      });
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <div>
              <Row style={{ width: "auto" }}>
                <Col>
                  <Text className="start">
                    {state?.title || "All profiles"}
                  </Text>
                  <Row>
                    {filteredProfiles?.length > 0 &&
                      filteredProfiles?.map((profile, index) => {
                        return (
                          index < 12 && (
                            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                              <AllProfileUserCard
                                navigate={navigate}
                                profileItem={profile}
                                shortlistClick={() => shortlistClick(profile)}
                                interestClick={() => interestClick(profile)}
                                ignoreClick={() => ignoreClick(profile)}
                                acceptInterestClick={
                                  isVisibleUpdateInterest
                                    ? updateInterestClick
                                    : null
                                }
                                declineInterestClick={
                                  isVisibleUpdateInterest
                                    ? updateInterestClick
                                    : null
                                }
                              />
                            </Col>
                          )
                        );
                      })}
                  </Row>
                  <p>
                    <Pagination
                      total={allProfilesData?.length}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
