import React from "react";
import { Card, Layout, Typography, Row, Col } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import NavBar from "../genericComponents/CustomNav";

const { Content } = Layout;

export const HelpCenter = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;

  const containerStyle = {
    padding: '16px',
    maxWidth: '1200px',
    margin: '0 auto'
  };

  const imageStyle = {
    width: '100%',
    maxWidth: '500px',
    height: 'auto',
    objectFit: 'contain',
    margin: '20px auto',
    display: 'block'
  };

  const cardStyle = {
    width: '100%',
    height: '80px',
    marginBottom: '16px',
    transition: 'all 0.3s ease',
    cursor: 'pointer'
  };

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none'
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div style={containerStyle}>
            {/* Title */}
            <Row justify="center">
              <Col xs={24} sm={24} md={24}>
                <Text className="midtitle" style={{ fontSize: '24px', display: 'block', textAlign: 'center' }}>
                  {state?.title || "Help Center"}
                </Text>
              </Col>
            </Row>

            {/* Image */}
            <Row justify="center">
              <Col xs={24} sm={20} md={16}>
                <img
                  style={imageStyle}
                  src="https://img.freepik.com/free-vector/flat-customer-support-illustration_23-2148899114.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1716940800&semt"
                  alt="HelpCenter"
                />
              </Col>
            </Row>

            {/* Contact Cards */}
            <Row gutter={[16, 16]} justify="center">
              <Col xs={24} sm={24} md={12}>
                <Card 
                  hoverable
                  style={cardStyle}
                  bodyStyle={{ height: '100%', display: 'flex', alignItems: 'center' }}
                >
                  <a href="tel:+919452613159" style={linkStyle}>
                    <Typography.Text strong style={{ fontSize: '16px' }}>
                      Phone: +91-9452613159
                    </Typography.Text>
                  </a>
                </Card>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Card 
                  hoverable
                  style={cardStyle}
                  bodyStyle={{ height: '100%', display: 'flex', alignItems: 'center' }}
                >
                  <a href="mailto:connectinghearts.info@gmail.com" style={linkStyle}>
                    <Typography.Text strong style={{ fontSize: '16px' }}>
                      Email: connectinghearts.info@gmail.com
                    </Typography.Text>
                  </a>
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
