import React, { useState } from "react";
import {
  Button,
  Carousel,
  Col,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Typography,
  theme,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSider } from "../genericComponents/CustomSider";
import Password from "antd/es/input/Password";
import { CustomHeader } from "../genericComponents/CustomHeader";
import axios from "axios";
import NavBar from "../genericComponents/CustomNav";
import {
  getallprofiles,
  getblockprofile,
  getignoreprofile,
  getunblockprofile,
  getunignoreprofile,
  patchchangePassword,
  updateInterest,
} from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
import { AllProfileUserCard } from "../genericComponents/UserProfileCard";

const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const Acceptance = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [value1, setValue1] = useState("acceptedMe");

  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [screenName, setScreenName] = useState("acceptedMe");

  const onChange1 = ({ target: { value } }) => {
    //console.log("radio1 checked", value);
    setValue1(value);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getAcceptedUI(value1);
    setScreenName(value1);
  }, [value1]);

  const getAcceptedUI = async (type) => {
    const res = await getallprofiles(`dashboard/getAcceptanceProfiles/${type}`);
    setFilteredProfiles(res?.filteredProfiles);
  };

  const updateInterestClick = (profileItem, status) => {
    const reqbody = {
      _id: profileItem?.clientID,
      status: status,
    };
    updateInterest(reqbody).then(async (res) => {
      if (res?.code === "CH200") {
        getAcceptedUI(value1);
        toast.success(res?.message);
      }
    });
  };

  const ignoreClick = (profileItem) => {
    if (profileItem?.isIgnored) {
      getunignoreprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          profileItem.isIgnored = false;
          toast.success(res?.message);
        }
      });
    } else {
      getignoreprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          profileItem.isIgnored = true;
          toast.success(res?.message);
        }
      });
    }
  };

  const blockClick = (profileItem) => {
    if (profileItem?.isBlocked) {
      getunblockprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          getAcceptedUI(value1);
          toast.success(res?.message);
        }
      });
    } else {
      getblockprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          getAcceptedUI(value1);
          toast.success(res?.message);
        }
      });
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Radio.Group
              onChange={onChange1}
              value={value1}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button value="acceptedMe">Accepted Me</Radio.Button>
              <Radio.Button value="acceptedByMe">Accepted By Me</Radio.Button>
            </Radio.Group>
          </div>
          <Row>
            {filteredProfiles?.length > 0 &&
              filteredProfiles?.map((profile, index) => {
                return (
                  index < 12 && (
                    <Col sm={24} md={12} lg={12} xl={8} xxl={8}>
                      <AllProfileUserCard
                        navigate={navigate}
                        profileItem={profile}
                        screenName={screenName}
                        acceptInterestClick={updateInterestClick}
                        declineInterestClick={updateInterestClick}
                        ignoreClick={() => ignoreClick(profile)}
                        blockClick={() => blockClick(profile)}
                      />
                    </Col>
                  )
                );
              })}
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};
