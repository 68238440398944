import { Button, Col, Form, Modal, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { almostDoneFormFields } from "../constants/almostDoneFormFields";
import { useNavigate } from "react-router-dom";
import { updateAboutMeData } from "../store/slices/aboutMeSlice";
import AjaXpress from "../helperFunctions/api";
import { uploadFiles } from "../helperFunctions/utils";
import {
  CaretLeftOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  setDescription,
  setProfilePicDetails,
} from "../store/slices/userSlice";
import { toast } from "react-toastify";
import {
  getUserDetails,
  getUserDetailsFromLocalStorage,
  updateLastScreenActivity,
} from "../helperFunctions/endpoints";
import ImageUploadModal from "../genericComponents/ImageUploadModal";

const createImageUrl = (userId, fileName) => {
  return `${process.env.REACT_APP_API_URL}profile/file/${userId}/${fileName}`;
};

const AlmostDoneForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(almostDoneFormFields);
  const [userDetails, setUserDetails] = useState({});
  const primaryProfilePicId = userDetails?.profilePicId;
  const s3Link = userDetails?.profileS3Link;
  const description = userDetails?.description;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    primaryProfilePicId
      ? createImageUrl(userDetails?._id, primaryProfilePicId)
      : ""
  );
  const [fileObj, setFileObj] = useState();
  const [loader, setLoader] = useState(false);

  const handleFormChanges = async (name, value) => {
    form.setFieldValue(name, value);
    dispatch(setDescription(value));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateUserDetails();
    updateLastScreenActivity("profilepic");
  }, []);

  const updateUserDetails = async () => {
    let userDetails = getUserDetailsFromLocalStorage();
    if (!userDetails?._id) {
      userDetails = await getUserDetails();
    }
    setUserDetails(userDetails);
  };

  const addProfileDetails = async () => {
    setLoader(true);
    try {
      let response = await AjaXpress({
        method: "PATCH",
        body: {
          description: description,
          s3Link: s3Link,
          id: primaryProfilePicId,
          primary: true,
        },
        slug: "profile",
      });
      if (response?.response?.status == 200) {
        navigate("/familydetails");
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoader(false);
  };

  const handleNext = (values) => {
    addProfileDetails();
  };

  const handleChange = (info) => {
    setFileObj(info.file.originFileObj);
  };

  const uploadPicToUrl = async (fileObj) => {
    setLoading(true);
    let userDetails = getUserDetailsFromLocalStorage();
    if (!userDetails?._id) {
      userDetails = await getUserDetails();
    }
    try {
      let res = await uploadFiles("profile/uploadProfilePic", {
        profilePhoto: fileObj,
        primary: true,
      });
      setImageUrl(createImageUrl(userDetails?._id, res?.data?.id));
      //console.log("image", createImageUrl(userDetails?._id, res?.data?.id));
      dispatch(
        setProfilePicDetails({ id: res?.data?.id, s3Link: res?.data?.fileName })
      );
    } catch (error) {
      //console.log(error);
    }
    setLoading(false);
    setShowImageUploadModal(false);
  };

  const uploadButton = (
    // Upload Button
    <div
      style={{
        display: "flex",
        width: "300px",
        height: "200px",
        border: "2px dashed gray",
        borderRadius: "5px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        setShowImageUploadModal(true);
      }}
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handlePreview = async () => {
    setPreviewOpen(true);
  };
  const handleCancel = () => setPreviewOpen(false);

  return (
    <Row type="flex" align="middle" justify={"center"}>
      <Col xs={24} sm={24} md={24} lg={16} xl={12}>
        <div className="login-margin" style={{ marginBottom: "3rem" }}>
          <Progressbar percent={100} />
          <div className="sub-title">About You</div>
          <div>Profile Pic</div>
          {/* {uploadButton} */}
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                maxHeight: "8rem",
              }}
            />
          ) : (
            uploadButton
          )}
          <Form
            layout="vertical"
            name="loginForm"
            form={form}
            onFinish={handleNext}
            initialValues={{
              description: description,
            }}
          >
            {fields?.map((Field, index) => {
              return (
                <React.Fragment key={index}>
                  <Form.Item
                    label={Field.label}
                    name={Field.name}
                    rules={Field.rules}
                    style={Field.style ? Field.style : {}}
                    {...(Field.feedBack && { hasFeedback: Field.feedBack })}
                    {...(Field.valuePropName && {
                      valuePropName: Field.valuePropName,
                    })}
                  >
                    {formFieldRenderer(Field, handleFormChanges)}
                  </Form.Item>
                </React.Fragment>
              );
            })}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
                loading={loader}
              >
                Create My Profile
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="text"
            block
            className="border-Radius-5px block-button-text height-100p back-button"
            onClick={() => navigate("/familydetails")}
          >
            I will fill this later
          </Button>
          <Button
            type="text"
            block
            className="border-Radius-5px block-button-text height-100p back-button"
            onClick={() => navigate("/srcmform")}
          >
            <CaretLeftOutlined />
            Back
          </Button>
        </div>
        <Modal
          open={previewOpen}
          footer={null}
          onCancel={handleCancel}
          width={"fit-content"}
          styles={{ body: { margin: 0 } }}
          closeIcon={
            <div
              style={{
                background: "white",
                color: "red",
                borderRadius: "50%",
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              X
            </div>
          }
        >
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        </Modal>
      </Col>
      <ImageUploadModal
        isModalOpen={showImageUploadModal}
        loading={loading}
        subTitle={"Image Upload"}
        uploadClickButton={uploadPicToUrl}
        onCancelClick={() => setShowImageUploadModal(false)}
      />
    </Row>
  );
};

export default AlmostDoneForm;
