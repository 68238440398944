import { Button, Col, Form, Typography, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import {
  editAboutFormFields,
  editCareerFormFields,
  editContactDetailsFormFields,
  editEducationFormFields,
  editFamilyFormFields,
  editHoroscopeFormFields,
  editLifeStyleFormFields,
  editPersonalDetailsFormFields,
  personalDetailsFormFields,
} from "../constants/personalDetailsFormFields";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { setCities, setStates } from "../store/slices/lookupSlice";
import AjaXpress from "../helperFunctions/api";
import { updatePersonalData } from "../store/slices/personalDetailSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CaretLeftOutlined } from "@ant-design/icons";
import NavBar from "../genericComponents/CustomNav";
import { CustomSider } from "../genericComponents/CustomSider";
import {
  getPersonalDetails,
  updateLastScreenActivity,
} from "../helperFunctions/endpoints";

const EditProfile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const lookupData = useSelector((state) => state.lookup.lookupData);
  const countries = useSelector((state) => state.lookup.countries);
  const states = useSelector((state) => state.lookup.states);
  const cities = useSelector((state) => state.lookup.cities);
  const [personalDetails, setPersonalDetails] = useState();
  const [loading, setLoading] = useState({ get: false, set: false });
  const { Header, Content, Footer } = Layout;

  let { id } = useParams();

  const getPersonalData = async () => {
    setLoading((loading) => {
      return { ...loading, get: true };
    });
    try {
      let response = await getPersonalDetails();
      if (response?.code == "CH200") {
        let data = response?.data;
        //console.log("sachin123", data);

        let obj = {
          description: data?.about?.description,
          disability: data?.about?.disability,
          bodyType: data?.about?.bodyType,
          thalassemia: data?.about?.thalassemia,
          hivPositive: data?.about?.hivPositive,
          managedBy: data?.about?.managedBy,
          religion: data?.basic?.religion,
          cast: data?.basic?.cast,
          city: data?.basic?.city,
          country: data?.basic?.country,
          state: data?.basic?.state,
          height: data?.basic?.height,
          income: data?.basic?.income,
          motherTongue: data?.basic?.motherTongue,
          name: data?.basic?.name,
          aboutMyCareer: data?.career?.aboutMyCareer,
          employed_in: data?.career?.employed_in,
          interestedInSettlingAbroad: data?.career?.interestedInSettlingAbroad,
          occupation: data?.career?.occupation,
          organisationName: data?.career?.organisationName,
          altMobileNumber: data?.contact?.altMobileNumber,
          alternateEmail: data?.contact?.alternateEmail,
          landline: data?.contact?.landline,
          email: data?.contact?.email,
          phoneNumber: data?.contact?.phoneNumber,
          aboutEducation: data?.education?.aboutEducation,
          otherUGDegree: data?.education?.otherUGDegree,
          qualification: data?.education?.qualification,
          school: data?.education?.school,
          aboutMyFamily: data?.family?.aboutMyFamily,
          brothers: data?.family?.brothers,
          familyBasedOutOf: data?.family?.familyBasedOutOf,
          familyIncome: data?.family?.familyIncome,
          familyStatus: data?.family?.familyStatus,
          familyType: data?.family?.familyType,
          familyValues: data?.family?.familyValues,
          fatherOccupation: data?.family?.fatherOccupation,
          gothra: data?.family?.gothra,
          livingWithParents: data?.family?.livingWithParents,
          marriedBrothers: data?.family?.marriedBrothers,
          marriedSisters: data?.family?.marriedSisters,
          motherOccupation: data?.family?.motherOccupation,
          sisters: data?.family?.sisters,
          horoscope: data?.horoscope?.horoscope,
          manglik: data?.horoscope?.manglik,
          nakshatra: data?.horoscope?.nakshatra,
          rashi: data?.horoscope?.rashi,
          countryOfBirth: data?.horoscope?.countryOfBirth,
          stateOfBirth: data?.horoscope?.stateOfBirth,
          cityOfBirth: data?.horoscope?.cityOfBirth,
          timeOfBirth: data?.horoscope?.timeOfBirth,
          books: data?.lifeStyleData?.books,
          cuisine: data?.lifeStyleData?.cuisine,
          dietaryHabits: data?.lifeStyleData?.dietaryHabits,
          dress: data?.lifeStyleData?.dress,
          drinkingHabits: data?.lifeStyleData?.drinkingHabits,
          favMusic: data?.lifeStyleData?.favMusic,
          favRead: data?.lifeStyleData?.favRead,
          favTVShow: data?.lifeStyleData?.favTVShow,
          foodICook: data?.lifeStyleData?.foodICook,
          hobbies: data?.lifeStyleData?.hobbies,
          interest: data?.lifeStyleData?.interest,
          languages: data?.lifeStyleData?.languages,
          movies: data?.lifeStyleData?.movies,
          openToPets: data?.lifeStyleData?.openToPets,
          ownACar: data?.lifeStyleData?.ownACar,
          ownAHouse: data?.lifeStyleData?.ownAHouse,
          smokingHabits: data?.lifeStyleData?.smokingHabits,
          sports: data?.lifeStyleData?.sports,
          vacayDestination: data?.lifeStyleData?.vacayDestination,
        };
        Object.keys(obj)?.forEach((item) => {
          handleFormChanges(item, obj[item]);
          if (item === "country" && obj["country"]) {
            getStateLookups(obj["country"]);
          }
          if (item === "state" && obj["state"]) {
            getCityLookus(obj["state"]);
          }
        });
        setPersonalDetails(obj);
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, get: false };
    });
  };

  useEffect(() => {
    getPersonalData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Object.keys(lookupData).length) {
      let fields;
      let data = [];
      if (id === "basic") {
        fields = editPersonalDetailsFormFields;
      } else if (id === "about") {
        fields = editAboutFormFields;
      } else if (id === "education") {
        fields = editEducationFormFields;
      } else if (id === "career") {
        fields = editCareerFormFields;
      } else if (id === "family") {
        fields = editFamilyFormFields;
      } else if (id === "contact") {
        fields = editContactDetailsFormFields;
      } else if (id === "horoscope") {
        fields = editHoroscopeFormFields;
      } else if (id === "lifestyle") {
        fields = editLifeStyleFormFields;
      }
      data = fields.map((field) => {
        return {
          ...field,
          defaultValueGetter: personalDetails,
          ...(field?.apiLookups === "country" && {
            options: countries,
          }),
          ...(field?.apiLookups === "state" && {
            options: states,
          }),
          ...(field?.apiLookups === "city" && {
            options: cities,
          }),
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      setFields(data);
    }
  }, [lookupData, countries, states, cities, personalDetails, id]);

  const onFormChangeHandler = async (field) => {
    try {
      if (field.hasOwnProperty("country")) {
        getStateLookups(field.country);
      }
      if (field.hasOwnProperty("state")) {
        getCityLookus(field.state);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getStateLookups = async (country) => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `lookup/getStateLookup/${country}`,
      });
      if (response?.response?.status == 200) {
        dispatch(setStates(response?.data[0]?.states));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getCityLookus = async (state) => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `lookup/getCityLookup/${state}`,
      });
      if (response?.response?.status == 200) {
        dispatch(setCities(response?.data[0]?.cities));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
    if (name === "country") {
      getStateLookups(value);
    } else if (name === "state") {
      getCityLookus(value);
    }
    dispatch(
      updatePersonalData({
        key: name,
        value: name === "dob" ? dayjs(value).format() : value,
      })
    );
  };

  const addPersonalDetails = async (values) => {
    setLoading((loading) => {
      return { ...loading, set: true };
    });
    try {
      values.section = id;
      let response = await AjaXpress({
        method: "PATCH",
        body: values,

        // body: {
        //   gender: personalDetails.gender,
        //   dob: personalDetails.dob,
        //   height: personalDetails.height,
        //   employed_in: personalDetails.employedIn,
        //   country: personalDetails.country,
        //   state: personalDetails.state,
        //   city: personalDetails.city,
        //   motherTongue: personalDetails.motherTongue,
        //   religion: personalDetails.religion,
        //   cast: personalDetails.caste,
        //   horoscope: personalDetails.horoscope,
        //   manglik: personalDetails.manglik,
        //   income: personalDetails.income,
        //   residentialStatus: personalDetails.residentialStatus,
        //   maritalStatus: personalDetails.maritalStatus,
        //   occupation: personalDetails.occupation,
        //   haveChildren: personalDetails.haveChildren,
        //   castNoBar: personalDetails.castNoBar,
        //   education: {
        //     qualification: personalDetails.education,
        //     otherUGDegree: personalDetails.otherPgDegree,
        //   },
        // },
        slug: "personalDetails/editProfile",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);
        if (id) {
          navigate("/myprofile");
        } else {
          navigate("/careerdetails");
        }
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, set: false };
    });
  };
  const handleNext = (values) => {
    addPersonalDetails(values);
  };

  const handlePrev = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userDetails");
    navigate("/registration");
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  return (
    <Layout>
      {!!id && <NavBar navigate={navigate} />}
      <Layout>
        {!!id && <CustomSider navigate={navigate} />}
        <Content className="content-wrapper">
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={16} xl={12}>
              <div className="login-margin" style={{ marginBottom: "3rem" }}>
                {!id && <Progressbar percent={20} />}
                <div className="sub-title">Edit Profile</div>

                <Form
                  layout="vertical"
                  name="loginForm"
                  form={form}
                  onFinish={handleNext}
                  onValuesChange={onFormChangeHandler}
                  initialValues={personalDetails}
                >
                  {fields?.map((Field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Item
                          label={Field.label}
                          name={Field.name}
                          rules={Field.rules}
                          style={Field.style ? Field.style : {}}
                          {...(Field.feedBack && {
                            hasFeedback: Field.feedBack,
                          })}
                          {...(Field.valuePropName && {
                            valuePropName: Field.valuePropName,
                          })}
                        >
                          {formFieldRenderer(Field, handleFormChanges)}
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="border-Radius-5px block-button-text height-100p"
                      loading={loading.set}
                    >
                      {id ? "Update" : "Next"}
                    </Button>
                  </Form.Item>
                </Form>
                {!id && (
                  <Button
                    type="text"
                    block
                    className="border-Radius-5px block-button-text height-100p back-button"
                    onClick={() => handlePrev()}
                  >
                    <CaretLeftOutlined />
                    Back
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditProfile;
