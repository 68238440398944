import malePartner from "../images/m.png";
import femalePartner from "../images/f.png";
import malePlaceholder from "../images/male-placeholder.png";
import femalePlaceholder from "../images/female-placeholder.png";
import {
  getCitieslookup,
  getCountryLookup,
  getStatelookup,
  getUserDetailsFromLocalStorage,
  getallprofiles,
  getjustjoined,
  getprofilevisitors,
} from "../helperFunctions/endpoints";

export const getLabelfromLookup = (value, lookup, key) => {
  if (value < 0 || !lookup) {
    return "";
  }
  const findLookupArr = lookup[key];
  if (Array.isArray(value)) {
    const findValues = value.map((x) => {
      const foundItem = findLookupArr.find((item) => item.value === x);
      return foundItem ? foundItem.label : "";
    });
    return findValues.join(",") || [];
  }

  const foundItem = findLookupArr?.find((item) => item.value == value);

  return foundItem ? foundItem.label : "";
};

export const getLookupfromLocal = async (lookupData, lookupKey) => {
  let expData = JSON.parse(lookupData);
  let getData = [];
  if (lookupData) {
    expData.forEach((item) => {
      if (item[lookupKey]) {
        getData = item[lookupKey];
      }
    });
  }
  return getData;
};

export const getDateFormatted = (date) => {
  const dateTimeString = date;
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = dateTime.getDate();

  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  return formattedDate;
};

export const getCountryLabel = async (countries, country) => {
  try {
    let findCountry = countries?.find((item) => item.value == country);
    return findCountry.label;
  } catch (error) {
    console.error("Error:", error);
    return { stateLabel: "Error", cityLabel: "Error" };
  }
};
export const getCountriesLabel = async (country) => {
  try {
    let countries = await getCountryLookup();
    let findCountry = countries?.filter((item) =>
      country?.includes(item.value)
    );
    return findCountry;
  } catch (error) {
    console.error("Error:", error);
    return { stateLabel: "Error", cityLabel: "Error" };
  }
};

export const findLabelByValueAndKey = (value, lookupKey) => {
  try {
    const lookupData = localStorage.getItem("lookupData");
    if (lookupData) {
      let expData = JSON.parse(lookupData);
      let lookupDataItem = expData[lookupKey];

      if (lookupDataItem) {
        let findData = lookupDataItem.find((item) => item.value === value);
        if (findData) {
          return findData.label;
        }
      }
    }

    return "";
  } catch (error) {
    console.error("Error retrieving lookup data:", error);
    return "";
  }
};

export const calculateAge = (birthdateISO) => {
  const birthdate = new Date(birthdateISO);
  const currentDate = new Date();

  const years = currentDate.getFullYear() - birthdate.getFullYear();
  const months = currentDate.getMonth() - birthdate.getMonth();
  const days = currentDate.getDate() - birthdate.getDate();

  if (months < 0 || (months === 0 && days < 0)) {
    return years - 1;
  }

  return years;
};

export const getProfilePicAsURL = (clientID, allProfilePhotos) => {
  if (clientID && allProfilePhotos) {
    allProfilePhotos.forEach((item, i) => {
      const key = item.primary ? "primaryUrl" : `url${i + 1}`;
      allProfilePhotos[
        key
      ] = `${process.env.REACT_APP_API_URL}/profile/file/${clientID}/${item.id}`;
    });
    return allProfilePhotos?.primaryUrl || "";
  }
  return "";
};

export const getErrorImgForProfile = (userData) => {
  if (!userData?._id) userData = getUserDetailsFromLocalStorage();
  if (userData?.gender === "M") {
    return malePlaceholder;
  } else {
    return femalePlaceholder;
  }
};

export const getErrorImgForOtherProfile = () => {
  const userData = getUserDetailsFromLocalStorage();
  if (userData?.gender === "F") {
    return malePlaceholder;
  } else {
    return femalePlaceholder;
  }
};

export const getErrorImgForPreference = (userData) => {
  if (userData?.gender === "M") {
    return femalePartner;
  } else {
    return malePartner;
  }
};

export const getStateAndCityLabels = async (country, state, city) => {
  try {
    const labels = {};
    if (country) {
      const countryResponse = await getCountryLookup();
      const countryLabel = countryResponse?.find(
        (item) => item?.value === country
      );
      if (countryLabel?.label) labels["country"] = countryLabel?.label;
    }
    if (state) {
      const stateResponse = await getStatelookup(country);
      const stateData = stateResponse[0]?.states;
      const stateLabel = stateData?.find((item) => item?.value === state);
      if (stateLabel?.label) labels["state"] = stateLabel?.label;
    }
    if (city) {
      const cityResponse = await getCitieslookup(state);
      const cityData = cityResponse[0]?.cities;
      const cityLabel = cityData?.find((item) => item?.value === city) || "";
      if (cityLabel?.label) labels["city"] = cityLabel.label;
    }

    return labels;
  } catch (error) {
    console.error("Error:", error);
    return ["Error", "Error", "Error"];
  }
};

export const getTimeFormatted = (date) => {
  const dateTime = new Date(date);
  // Convert to local time
  const localHours = dateTime.getHours();
  const localMinutes = dateTime.getMinutes();
  const ampm = localHours >= 12 ? "PM" : "AM";
  const formattedHours = (localHours % 12 || 12).toString().padStart(2, "0"); // Convert 0 to 12 for AM
  const formattedMinutes = localMinutes.toString().padStart(2, "0");
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedTime;
};

export const gettimestapm = async (date) => {
  const dateObject = new Date(date);
  const timestampUsingGetTime = dateObject.getTime();
  return timestampUsingGetTime;
};

export const getAgeByDate = (date) => {
  // Date of birth in DD/MM/YYYY format
  const dobString = date;

  // Split the date string into day, month, and year components
  const dobParts = dobString.split("/");
  const day = parseInt(dobParts[0], 10);
  const month = parseInt(dobParts[1], 10) - 1; // Subtract 1 from the month as months are 0-based
  const year = parseInt(dobParts[2], 10);

  // Create a Date object for the birthdate
  const dobDate = new Date(year, month, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age
  const age = currentDate.getFullYear() - dobDate.getFullYear();

  // Check if the birthday hasn't occurred this year yet
  if (
    currentDate.getMonth() < dobDate.getMonth() ||
    (currentDate.getMonth() === dobDate.getMonth() &&
      currentDate.getDate() < dobDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const keyIntoTitle = (key) => {
  if (key) {
    const convertedString = key
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return convertedString;
  }
};

export const ProfileNavScreen = {
  InterestSentScreen: "InterestSentScreen",
  InterestReceivedScreen: "InterestReceivedScreen",
  ShortlistScreen: "ShortlistScreen",
  BlockedScreen: "BlockedScreen",
  UnlockedScreen: "UnlockedScreen",
  IDeclinedScreen: "IDeclinedScreen",
  TheyDeclinedScreen: "TheyDeclinedScreen",
  IgnoredScreen: "IgnoredScreen",
};

export const ProfileListScreen = {
  all: {
    title: "All Profiles",
    api: "dashboard/getAllProfiles",
    dataKey: "filteredProfiles",
  },
  justjoined: {
    title: "Just Joined",
    api: "dashboard/getJustJoined",
    dataKey: "filteredProfiles",
  },
  profilevisitors: {
    title: "Profile Visitors",
    api: "dashboard/getProfileVisitors",
    dataKey: "filteredProfiles",
  },
  dailyrecommendations: {
    title: "Daily Recommendations",
    api: "interest/getDailyRecommendations",
    dataKey: "filteredProfiles",
  },
  interestreceived: {
    title: "Interest Received",
    api: "interest/getInterests",
    dataKey: "filteredProfiles",
  },
  interestsent: {
    title: "Interest Sent",
    api: "dashboard/getMyInterestedProfiles",
    dataKey: "filteredProfiles",
  },
  shortlist: {
    title: "Shortlisted Profiles",
    api: "dashboard/getMyShortlistedProfiles",
    dataKey: "shortlistedProfilesData",
  },
  blocked: {
    title: "Blocked Profiles",
    api: "dashboard/getMyBlockedProfiles",
    dataKey: "filteredProfiles",
  },
  unlockedprofiles: {
    title: "Unlocked Profiles",
    api: "dashboard/getMyUnlockedProfiles",
    dataKey: "filteredProfiles",
  },
  ideclined: {
    title: "I Declined",
    api: "dashboard/getMyDeclinedProfiles",
    dataKey: "filteredProfiles",
  },
  theydeclined: {
    title: "They Declined",
    api: "dashboard/getUsersWhoHaveDeclinedMe",
    dataKey: "filteredProfiles",
  },
  ignored: {
    title: "Ignored Profiles",
    api: "dashboard/getAllIgnoredProfiles",
    dataKey: "ignoreListData",
  },
};
