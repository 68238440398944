import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Layout,
  Progress,
  Row,
  Typography,
  theme,
} from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import NavBar from "../genericComponents/CustomNav";
import { CustomHeader } from "../genericComponents/CustomHeader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  getMembershipList,
  getbuyMembership,
  getmymembershipdetails,
  getverifyPayment,
} from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
import GoldPlanImage from "../images/goldplan.png";
import GoldBatchImage from "../images/goldbatch.png";
const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const Membership = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [membershipList, setMembershipList] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState([]);

  useEffect(() => {
    getMembershipListForUI();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get("orderId");
    if (orderId) {
      verifyOrder(orderId);
    }
  }, [window.location]);

  const getMembershipListForUI = async () => {
    const membership = await getMembershipList();
    const membershipDetails = await getmymembershipdetails();
    setMembershipDetails(membershipDetails?.membershipData);
    setMembershipList(membership);
  };

  const onUpgradeClick = async (item) => {
    const cashfree = window.Cashfree({
      mode: "production", //or production
    });
    const { orderId, sessionId } = await getbuyMembership(item._id);
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl:
        process.env.REACT_APP_PAYMENT_CALLBACK_URL + "?orderId=" + orderId,
    };
    cashfree.checkout(checkoutOptions).then(function (result) {
      if (result.error) {
        alert(result.error.message);
        verifyOrder(orderId);
      }
      if (result.redirect) {
        //console.log("Redirection");
        verifyOrder(orderId);
      }
    });
  };

  const verifyOrder = async (orderId) => {
    const response = await getverifyPayment(orderId);
    if (response?.code === "CH200") {
      toast.success(response?.message);
    } else {
      toast.error(response?.err);
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />

      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <div style={{ margin: "16px" }}>
            <img src={GoldBatchImage} alt="no-img" />
            <Text>
              You have left {membershipDetails?.heartCoins} heart coins
            </Text>
            <Progress
              percent={membershipDetails?.heartCoins}
              showInfo={false}
            />
          </div>

          {membershipList.length > 0 &&
            membershipList.map((item) => {
              const isSamePurchasedPlan =
                item?._id === membershipDetails?.membership_id;
              return (
                <Card
                  bordered={false}
                  style={{
                    margin: "8px",
                    backgroundImage: isSamePurchasedPlan
                      ? `url(${GoldPlanImage})`
                      : "none",
                    backgroundSize: "cover",
                  }}
                >
                  <span
                    style={{
                      color: isSamePurchasedPlan ? "#ffffff" : "#f5222d",
                    }}
                  >
                    <b>
                      <strong>{item?.planName} Plan</strong>
                    </b>
                  </span>
                  <ul
                    style={{
                      color: isSamePurchasedPlan ? "#ffffff" : "#000000",
                    }}
                  >
                    {item?.features?.map((feature) => {
                      return <li>{feature}</li>;
                    })}
                  </ul>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      borderRadius: borderRadiusLG,
                      backgroundColor: isSamePurchasedPlan
                        ? "#b41243"
                        : "#ffe8ef",
                      padding: "10px",
                    }}
                  >
                    <Typography.Text
                      style={{
                        flex: 1,
                        textAlign: "flex-start",
                        justifyContent: "flex-start",
                        color: isSamePurchasedPlan ? "#ffffff" : "#000000",
                      }}
                    >
                      Price:
                      <br />{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {item?.currency + " " + item?.membershipAmount}
                      </span>
                    </Typography.Text>
                    {!isSamePurchasedPlan && (
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => onUpgradeClick(item)}
                      >
                        Upgrade Now
                      </Button>
                    )}
                  </div>
                </Card>
              );
            })}
        </Content>
      </Layout>
    </Layout>
  );
};
