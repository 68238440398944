import React, { useEffect, useState } from "react";
import {
  Card,
  Carousel,
  Col,
  Form,
  Input,
  Layout,
  Row,
  theme,
  Tooltip,
  Button,
  Divider,
  Typography,
  Progress,
  Modal,
} from "antd";
import Logo from "../images/logo.jpeg";
import NoProfileImage from "../images/no-profile.png";
import { CustomSider } from "../genericComponents/CustomSider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getCountriesLabel,
  getDateFormatted,
  getErrorImgForOtherProfile,
  getErrorImgForProfile,
  getLabelfromLookup,
  getProfilePicAsURL,
  getStateAndCityLabels,
} from "../utils/utils";
import { FaUserGroup } from "react-icons/fa6";

import {
  RightCircleOutlined,
  HeartOutlined,
  EyeOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  SendOutlined,
  TeamOutlined,
  ContactsFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  getDetailsView,
  getLookupDataFromLocalStorage,
  getPersonalDetails,
  getUserDetailsFromLocalStorage,
  getblockprofile,
  getignoreprofile,
  getshortlist,
  getunblockprofile,
  getunignoreprofile,
  getunlockProfile,
  getunshortlist,
  getvisitprofile,
  sendInterest,
  unsendInterest,
  updateInterest,
} from "../helperFunctions/endpoints";
import { findLabelByValueAndKey } from "../utils/utils";
import { GiHeartBeats } from "react-icons/gi";
import { LiaHeartbeatSolid } from "react-icons/lia";
import NavBar from "../genericComponents/CustomNav";
import { ProfileImage } from "../genericComponents/UserProfileCard";
import ButtonWithIcon from "../genericComponents/ButtonWithIcon";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { BiChat, BiLock } from "react-icons/bi";
import { IoCall } from "react-icons/io5";
import { toast } from "react-toastify";
import ProfileActionsDefault from "../genericComponents/ProfileActionsDefault";
import ProfileActionsAcceptDecline from "../genericComponents/ProfileActionsAcceptDecline";
import ProfileActionsSingleButton from "../genericComponents/ProfileActionsSingleButton";
import ProfileActionsAccepted from "../genericComponents/ProfileActionsAccepted";
const { Header, Content, Footer } = Layout;

const createImageUrl = (userId, fileName) => {
  return `${process.env.REACT_APP_API_URL}profile/file/${userId}/${fileName}`;
};

export const ProfileView = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getProfiledata(id);
    updateProfileView();
  }, [id]);

  const [userdata, setUserData] = useState([]);
  const [myUserData, setMyUserData] = useState([]);
  const [cityLabels, setCityLabels] = useState();
  const [familyBasedCityLabels, setFamilyBasedCityLabels] = useState();
  const [placeOfBirthLabels, setPlaceOfBirthLabels] = useState();
  const [userPersonaldata, setUserPersonaldata] = useState([]);
  const [isMyProfileView, setIsMyProfileView] = useState(true);
  const location = useLocation();
  const { state } = location;
  const [lookupData, setLookupData] = useState(getLookupDataFromLocalStorage());
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
  const screenName = state?.screenName || "default";
  const [activeTab, setActiveTab] = useState("basicdetails");

  const getProfiledata = async (id) => {
    getDetailsView(id).then(async (res) => {
      if (res?.code === "CH200") {
        const profilePics = res?.data?.miscellaneous?.profilePic;
        const uniqueProfilePics = [
          ...new Set(profilePics.map((item) => item.id)),
        ].map((id) => {
          return profilePics.find((item) => item.id === id);
        });
        res.data.miscellaneous.profilePic = uniqueProfilePics;
        setUserData(res?.data?.miscellaneous);
        const labels = await getStateAndCityLabels(
          res?.data?.miscellaneous?.country,
          res?.data?.miscellaneous?.state,
          res?.data?.miscellaneous?.city
        );
        setCityLabels(labels);
        const familyCityLabels = await getCountriesLabel(
          res?.data?.family?.familyBasedOutOf
        );
        setFamilyBasedCityLabels(familyCityLabels[0]?.label);
        const placeOfBirthCityLabels = await getStateAndCityLabels(
          res?.data?.horoscope?.countryOfBirth,
          res?.data?.horoscope?.stateOfBirth,
          res?.data?.horoscope?.cityOfBirth
        );
        //console.log(placeOfBirthCityLabels);
        setPlaceOfBirthLabels(placeOfBirthCityLabels);
        //console.log("check123",res?.data);
        setUserPersonaldata(res?.data);
        if (screenName === "interestSent") {
          setUserData((prevData) => ({ ...prevData, isInterestSent: true }));
        } else if (screenName === "iDeclined") {
          setUserData((prevData) => ({ ...prevData, isIDeclined: true }));
        } else if (screenName === "blockedProfile") {
          setUserData((prevData) => ({ ...prevData, isBlocked: true }));
        } else if (screenName === "acceptedMe") {
          setUserData((prevData) => ({
            ...prevData,
            isInterestDeclined: false,
          }));
        }
      }
    });
  };

  const updateProfileView = async () => {
    const userData = getUserDetailsFromLocalStorage();
    setMyUserData(userData);
    //console.log(userData?._id, id);
    if (userData?._id !== id) {
      setIsMyProfileView(false);
      getvisitprofile(id).then((res) => {
        if (res?.code === "CH200") {
          //console.log(res);
        }
      });
    }
  };

  const interestClick = () => {
    if (userdata?.isInterestSent) {
      unsendInterest({ receiver_id: userdata?.clientID }).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isInterestSent: false }));
          toast.success(res?.message);
        }
      });
    } else {
      sendInterest({ targetId: userdata?.clientID }).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isInterestSent: true }));
          toast.success(res?.message);
        }
      });
    }
  };

  const updateInterestClick = (profileItem, status) => {
    const reqbody = {
      _id: profileItem?.clientID,
      status: status,
    };
    updateInterest(reqbody).then(async (res) => {
      if (res?.code === "CH200") {
        if (status === "accept") {
          setUserData((prevData) => ({
            ...prevData,
            isInterestReceived: true,
            isInterestDeclined: false,
            isIDeclined: false,
          }));
        } else if (status === "reject") {
          setUserData((prevData) => ({
            ...prevData,
            isInterestReceived: false,
            isInterestDeclined: true,
            isIDeclined: true,
          }));
        }
        toast.success(res?.message);
      }
    });
  };

  const shortlistClick = () => {
    if (userdata?.isShortlisted) {
      getunshortlist(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isShortlisted: false }));
          toast.success(res?.message);
        }
      });
    } else {
      getshortlist(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isShortlisted: true }));
          toast.success(res?.message);
        }
      });
    }
  };

  const handleContactClick = () => {
    if (userPersonaldata?.contact?.phoneNumber) {
      setIsUnlockModalOpen(true);
    } else {
      Modal.confirm({
        title: "Do you want to unlock this profile?",
        icon: <ExclamationCircleFilled />,
        content: "(This will cost you 1 heart coin!)",
        async onOk() {
          const resp = await getunlockProfile(userdata?.clientID);
          if (resp?.code === "CH200") {
            getProfiledata(id);
            setIsUnlockModalOpen(true);
          } else if (resp?.code === "CH400") {
            toast.error(resp?.err?.msg);
            if (resp?.err?.redirectToMembership) navigate("/membership");
          }
        },
        onCancel() {},
      });
    }
  };

  const handleChatClick = () => {
    toast.success("Chat feature coming soon!");
  };
  const hasData = (obj) => {
    const result = Object.values(obj || {}).some(value => value !== "" && value !== null);
    //console.log('hasData check for:', obj, 'result:', result);
    return result;
  };
  const blockClick = () => {
    if (userdata?.isBlocked) {
      getunblockprofile(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isBlocked: false }));
          toast.success(res?.message);
        }
      });
    } else {
      getblockprofile(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isBlocked: true }));
          toast.success(res?.message);
        }
      });
    }
  };

  const rowItem = (key, value) => {
    if (value) {
      if (Array.isArray(value) && value.length === 0) return;
      return (
        <Row>
          <Col span={12}>{key}</Col>
          <Col span={12}>{value}</Col>
        </Row>
      );
    }
  };

  const tabListNoTitle = [
    {
      key: "basicdetails",
      label: "Basic Details",
    },
    hasData(userPersonaldata?.family) && {
      key: "family",
      label: "Family",
    },
    hasData(userPersonaldata?.kundali) && {
      key: "kundali",
      label: "Kundali",
    },
    (!isMyProfileView) && {
      key: "match",
      label: "Match",
    },
  ].filter(Boolean);

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const tabData = {
    basicdetails:
      <div>
        <div style={{ padding: "8px" }}>
          {rowItem("Profile ID", "HEARTS-" + userdata?.heartsId)}
          {rowItem(
            "Height",
            findLabelByValueAndKey(userPersonaldata?.basic?.height, "height")
          )}
          {rowItem(
            "Location",
            [cityLabels?.city, cityLabels?.state, cityLabels?.country]
              .filter(Boolean)
              .join(", ")
          )}
        </div>
        <div
          style={{
            borderColor: "#f1c40f",
            backgroundColor: "#f1c40f2e",
            border: "1px",
            padding: "8px",
            borderRadius: "10px",
          }}
        >
          <Col className="col-title-color">Critical Field</Col>
          {rowItem(
            "Date of Birth",
            getDateFormatted(userPersonaldata?.critical?.dob)
          )}
          {rowItem(
            "Marital Status",
            findLabelByValueAndKey(
              userPersonaldata?.critical?.maritalStatus,
              "maritalStatus"
            )
          )}
          {rowItem(
            "Caste",
            findLabelByValueAndKey(userPersonaldata?.basic?.cast, "casts")
          )}
        </div>
        <div style={{ padding: "8px" }}>
          {hasData(userPersonaldata?.about) && (
            <Col className="col-title-color">About Me</Col>
          )}
          {rowItem("Description", userPersonaldata?.about?.description)}
          {rowItem(
            "Profile Managed by",
            findLabelByValueAndKey(userPersonaldata?.about?.managedBy, "managedBy")
          )}
          {rowItem(
            "Body Type",
            findLabelByValueAndKey(userPersonaldata?.about?.bodyType, "bodyType")
          )}
          {rowItem(
            "Thalassemia",
            findLabelByValueAndKey(userPersonaldata?.about?.thalassemia, "thalassemia")
          )}
          {rowItem(
            "HIV Positive",
            findLabelByValueAndKey(userPersonaldata?.about?.hivPositive, "single_decision")
          )}
        </div>
        <div style={{ padding: "8px" }}>
          {hasData(userPersonaldata?.education) && (
            <Col className="col-title-color">Educational Details</Col>
          )}
          {rowItem("About My Education", userPersonaldata?.education?.aboutEducation)}
          {rowItem("School", userPersonaldata?.education?.school)}
          {rowItem(
            "Qualification",
            findLabelByValueAndKey(userPersonaldata?.education?.qualification, "highestEducation")
          )}
          {rowItem(
            "Other UG Degree",
            userPersonaldata?.education?.otherUGDegree
          )}
        </div>
        <div style={{ padding: "8px" }}>
          {hasData(userPersonaldata?.career) && (
            <Col className="col-title-color">Career Details</Col>
          )}
          {rowItem("About My Career", userPersonaldata?.career?.aboutMyCareer)}
          {rowItem(
            "Employed In",
            findLabelByValueAndKey(userPersonaldata?.career?.employed_in, "employed_in")
          )}
          {rowItem(
            "Occupation",
            findLabelByValueAndKey(userPersonaldata?.career?.occupation, "occupation")
          )}
          {rowItem(
            "Organisation Name",
            userPersonaldata?.career?.organisationName
          )}
          {rowItem(
            "Interesting In Settling Abroad",
            findLabelByValueAndKey(userPersonaldata?.career?.interestedInSettlingAbroad, "single_decision")
          )}
          {rowItem(
            "Income",
            findLabelByValueAndKey(userPersonaldata?.career?.income, "income")
          )}
        </div>
      </div>
    ,
  
    family: hasData(userPersonaldata?.family) ? (
      <div>
        <div style={{ padding: "8px" }}>
          {hasData(userPersonaldata?.family) && (
            <Col className="col-title-color">Family Details</Col>
          )}
          {rowItem("About My Family", userPersonaldata?.family?.aboutMyFamily)}
          {rowItem(
            "Family Status",
            findLabelByValueAndKey(userPersonaldata?.family?.familyStatus, "familyStatus")
          )}
          {rowItem(
            "Family Type",
            findLabelByValueAndKey(userPersonaldata?.family?.familyType, "familyType")
          )}
          {rowItem(
            "Family Values",
            findLabelByValueAndKey(userPersonaldata?.family?.familyValues, "familyValues")
          )}
          {rowItem(
            "Family Income",
            findLabelByValueAndKey(userPersonaldata?.family?.familyIncome, "income")
          )}
          {rowItem(
            "Father Occupation",
            findLabelByValueAndKey(userPersonaldata?.family?.fatherOccupation, "fathersOccupation")
          )}
          {rowItem(
            "Mother Occupation",
            findLabelByValueAndKey(userPersonaldata?.family?.motherOccupation, "mothersOccupation")
          )}
          {rowItem(
            "Brothers",
            findLabelByValueAndKey(parseInt(userPersonaldata?.family?.brothers), "siblings")
          )}
          {rowItem(
            "Married Brothers",
            findLabelByValueAndKey(parseInt(userPersonaldata?.family?.marriedBrothers), "siblings")
          )}
          {rowItem(
            "Sisters",
            findLabelByValueAndKey(parseInt(userPersonaldata?.family?.sisters), "siblings")
          )}
          {rowItem(
            "Married Sisters",
            findLabelByValueAndKey(parseInt(userPersonaldata?.family?.marriedSisters), "siblings")
          )}
          {rowItem("Gothra", userPersonaldata?.family?.gothra)}
          {rowItem(
            "Living With Parent",
            findLabelByValueAndKey(userPersonaldata?.family?.livingWithParents, "livingWithParents")
          )}
          {rowItem("My family based out of", familyBasedCityLabels)}
        </div>
      </div>
    ) : null,
  
    kundali: hasData(userPersonaldata?.kundali) ? (
      <div>
        <div style={{ padding: "8px" }}>
          {hasData(userPersonaldata?.kundali) && (
            <Col className="col-title-color">Kundali & Astro</Col>
          )}
          {rowItem(
            "Rashi",
            findLabelByValueAndKey(userPersonaldata?.kundali?.rashi, "rashi")
          )}
          {rowItem(
            "Nakshatra",
            findLabelByValueAndKey(userPersonaldata?.kundali?.nakshatra, "nakshatra")
          )}
          {rowItem(
            "Place of birth",
            [
              placeOfBirthLabels?.country,
              placeOfBirthLabels?.state,
              placeOfBirthLabels?.city,
            ]
              .filter(Boolean)
              .join(", ")
          )}
          {rowItem("Time of birth", userPersonaldata?.kundali?.tob)}
          {rowItem(
            "Manglik",
            findLabelByValueAndKey(userPersonaldata?.kundali?.manglik, "manglik")
          )}
          {rowItem(
            "Horoscopes",
            findLabelByValueAndKey(userPersonaldata?.kundali?.horoscope, "horoscopes")
          )}
        </div>
        <div style={{ padding: "8px" }}>
          {hasData(userPersonaldata?.lifeStyleData) && (
            <Col className="col-title-color">Life Style</Col>
          )}
          {rowItem(
            "Dietary Habits",
            findLabelByValueAndKey(userPersonaldata?.lifeStyleData?.dietaryHabits, "dietaryHabits")
          )}
          {rowItem(
            "Drinking Habits",
            findLabelByValueAndKey(userPersonaldata?.lifeStyleData?.drinkingHabits, "drinkingHabits")
          )}
          {rowItem(
            "Own a House",
            findLabelByValueAndKey(userPersonaldata?.lifeStyleData?.ownAHouse, "smokingHabits")
          )}
          {rowItem(
            "Own a Car",
            findLabelByValueAndKey(userPersonaldata?.lifeStyleData?.ownACar, "smokingHabits")
          )}
          {rowItem(
            "Open to Pets",
            findLabelByValueAndKey(userPersonaldata?.lifeStyleData?.openToPets, "smokingHabits")
          )}
          {rowItem(
            "Smoking Habits",
            findLabelByValueAndKey(userPersonaldata?.lifeStyleData?.smokingHabits, "smokingHabits")
          )}
          {rowItem("Favorite Movies", userPersonaldata?.lifeStyleData?.movies)}
          {rowItem(
            "Language",
            getLabelfromLookup(userPersonaldata?.lifeStyleData?.languages, lookupData, "motherTongue")
          )}
          {rowItem("Food I Cook", userPersonaldata?.lifeStyleData?.foodICook)}
          {rowItem(
            "Favorite Music",
            getLabelfromLookup(userPersonaldata?.lifeStyleData?.favMusic, lookupData, "music")
          )}
          {rowItem(
            "Hobbies",
            getLabelfromLookup(userPersonaldata?.lifeStyleData?.hobbies, lookupData, "hobbies")
          )}
          {rowItem(
            "Interest",
            getLabelfromLookup(userPersonaldata?.lifeStyleData?.interest, lookupData, "interests")
          )}
          {rowItem(
            "Favorite Books",
            getLabelfromLookup(userPersonaldata?.lifeStyleData?.books, lookupData, "books")
          )}
          {rowItem(
            "Dress",
            getLabelfromLookup(userPersonaldata?.lifeStyleData?.dress, lookupData, "dressStyle")
          )}
        </div>
      </div>
    ) : null,

    match: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "8px",
            marginTop: "1rem",
            width: 220,
            height: 110,
            background: "#f8f8fc",
            borderRadius: "10px",
            justifyContent: "center",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {userdata?.clientID && (
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                userdata?.clientID,
                userdata?.profilePic
              )}
              errorImage={
                !isMyProfileView
                  ? getErrorImgForOtherProfile()
                  : getErrorImgForProfile()
              }
              width={60}
              height={100}
              style={{ transform: "rotate(345deg)", borderRadius: "10px" }}
            />
          )}
          <Progress
            type="circle"
            strokeWidth={20}
            size={50}
            style={{ zIndex: 2 }}
            strokeColor={"#FF004C"}
            percent={userPersonaldata?.matchPercentage}
          />
          {myUserData?._id && (
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                myUserData?._id,
                myUserData?.profilePic
              )}
              errorImage={
                !isMyProfileView
                  ? getErrorImgForOtherProfile()
                  : getErrorImgForProfile()
              }
              width={60}
              height={100}
              style={{ transform: "rotate(15deg)", borderRadius: "10px" }}
            />
          )}
        </div>
        <div style={{ padding: "8px" }}>
          {userPersonaldata?.matchData?.map((data) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 2,
                alignItems: "Start",
                gap: 2,
                marginBottom: 10,
              }}
            >
              <Text style={{ width: 280 }}>
                <b>{data?.label}</b>: <br />({data?.value})
              </Text>
              {data?.isMatched && (
                <CheckCircleFilled
                  style={{
                    color: "green",
                    fontSize: 24,
                  }}
                />
              )}
              {!data?.isMatched && (
                <CloseCircleFilled style={{ color: "grey", fontSize: 24 }} />
              )}
            </div>
          ))}
        </div>
      </div>
    ),
  };
  //console.log({tabData});
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Card
              style={{
                width: "100vw",
                maxWidth: 480,
                borderRadius: "10px",
                height: 490,
              }}
              cover={
                <div style={{ margin: "auto" }}>
                  <Carousel
                    dotPosition="bottom"
                    infinite={false}
                    style={{ width: "100%", maxWidth: 500 }}
                  >
                    {userdata?.clientID &&
                      userdata?.profilePic?.length > 0 &&
                      userdata?.profilePic?.map((data) => (
                        <ProfileImage
                          imgSrc={createImageUrl(userdata?.clientID, data?.id)}
                          errorImage={
                            !isMyProfileView
                              ? getErrorImgForOtherProfile()
                              : getErrorImgForProfile()
                          }
                          width={"100vw"}
                          maxWidth={500}
                          height={450}
                        />
                      ))}
                    {userdata?.profilePic?.length === 0 && (
                      <ProfileImage
                        imgSrc={
                          !isMyProfileView
                            ? getErrorImgForOtherProfile()
                            : getErrorImgForProfile()
                        }
                        errorImage={
                          !isMyProfileView
                            ? getErrorImgForOtherProfile()
                            : getErrorImgForProfile()
                        }
                        width={"100vw"}
                        maxWidth={500}
                        height={450}
                      />
                    )}
                  </Carousel>
                </div>
              }
              bodyStyle={{ padding: 0 }}
              tabList={tabListNoTitle}
              activeTabKey={activeTab}
              onTabChange={onTabChange}
              tabProps={{
                size: "middle",
                style: {
                  borderRadius: "10px",
                  position: "absolute",
                  bottom: 0,
                  left: "40%",
                  transform: "translateX(-40%)",
                },
              }}
            >
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 120,
                  backgroundColor: "#fff",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {tabData[activeTab]}
              </div>
            </Card>
          </div>
          {screenName === "iDeclined" && (
            <ProfileActionsSingleButton
              isSelected={userdata?.isIDeclined}
              defaultLabel={"Accept Again"}
              selectedLabel={"Decline"}
              handleClick={() => updateInterestClick(userdata, "reject")}
              screenName={screenName}
              handleSelectedClick={() =>
                updateInterestClick(userdata, "accept")
              }
            />
          )}
          {screenName === "interestSent" && (
            <ProfileActionsSingleButton
              isSelected={userdata?.isInterestSent}
              defaultLabel={"Cancel Interest"}
              selectedLabel={"Send Interest"}
              handleClick={interestClick}
              screenName={screenName}
              handleSelectedClick={interestClick}
            />
          )}
          {screenName === "blockedProfile" && (
            <ProfileActionsSingleButton
              isSelected={userdata?.isBlocked}
              defaultLabel={"Blocked"}
              selectedLabel={"Block"}
              handleClick={blockClick}
              screenName={screenName}
              handleSelectedClick={blockClick}
            />
          )}
          {screenName === "interestReceived" && (
            <ProfileActionsAcceptDecline
              userdata={userdata}
              acceptClick={updateInterestClick}
              declineClick={updateInterestClick}
            />
          )}
          {screenName === "acceptedMe" && (
            <ProfileActionsAccepted
              userdata={userdata}
              acceptDeclineClick={updateInterestClick}
              handleContactClick={handleContactClick}
              blockClick={blockClick}
              handleChatClick={handleChatClick}
            />
          )}
          {screenName === "default" && !isMyProfileView && (
            <ProfileActionsDefault
              userdata={userdata}
              interestClick={interestClick}
              handleContactClick={handleContactClick}
              shortlistClick={shortlistClick}
              blockClick={blockClick}
              handleChatClick={handleChatClick}
            />
          )}
        </Content>
        <Modal
          title="Basic Modal"
          open={isUnlockModalOpen}
          onCancel={() => setIsUnlockModalOpen(false)}
          footer={null}
        >
          <p>Name: {userPersonaldata?.contact?.name}</p>
          <p>
            Mobile Number:{" "}
            <a href={`tel:${userPersonaldata?.contact?.phoneNumber}`}>
              {userPersonaldata?.contact?.phoneNumber}
            </a>
          </p>
          <p>
            Email:{" "}
            <a href={`mailto:${userPersonaldata?.contact?.email}`}>
              {userPersonaldata?.contact?.email}
            </a>
          </p>
          <p>
            Alt Mobile Number:{" "}
            <a href={`tel:${userPersonaldata?.contact?.altMobileNumber}`}>
              {userPersonaldata?.contact?.altMobileNumber}
            </a>
          </p>
        </Modal>
      </Layout>
    </Layout>
  );
};
