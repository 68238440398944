import axios from "axios";
import AjaXpress, { getHeaders } from "./api";
import { getFromLocalStorage } from "./localStorage";

export const uploadFiles = async (apiUrl, reqPayload) => {
  try {
    let response = await AjaXpress({
      contentType: "multipart/form-data",
      method: "POST",
      body: reqPayload,
      slug: apiUrl,
    });
    return response;
  } catch (error) {
    //console.log(error);
  }
};

export const uploadProfilePicHelper = async (profilePhoto) => {
  let blob = await fetch(profilePhoto).then((r) => r.blob());
  const formData = new FormData();
  formData.append("profilePhoto", blob);
  formData.append("primary", true); // Assuming this retrieves your authentication token
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}profile/uploadProfilePic`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + (await getFromLocalStorage("access_token")),
        },
      }
    );
    return response; // Return the response data, assuming it contains useful information
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
};
