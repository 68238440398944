import { Button, Col, Layout, Form, Typography, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import { useDispatch, useSelector } from "react-redux";

import {
  partnerPreferencesAgeFormFields,
  partnerPreferencesFormFields,
  partnerPreferencesHeightFormFields,
  partnerPreferencesIncomeFormFields,
} from "../constants/partnerPreferenceFormFields";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Progressbar from "../genericComponents/Progressbar";
import { updatePartnerPreferences } from "../store/slices/partnerPreferenceSlice";
import AjaXpress from "../helperFunctions/api";
import { toast } from "react-toastify";
import { CaretLeftOutlined } from "@ant-design/icons";
import { CustomSider } from "../genericComponents/CustomSider";
import NavBar from "../genericComponents/CustomNav";
import { updateLastScreenActivity } from "../helperFunctions/endpoints";

const SignupPartnerPreferences = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(partnerPreferencesFormFields);
  const [fields1, setFields1] = useState(partnerPreferencesHeightFormFields);
  const [fields2, setFields2] = useState(partnerPreferencesAgeFormFields);
  const [fields3, setFields3] = useState(partnerPreferencesIncomeFormFields);
  const [loading, setLoading] = useState({ get: false, set: false });

  const lookupData = useSelector((state) => state.lookup.lookupData);
  const countries = useSelector((state) => state.lookup.countries);
  const partnerPreferences = useSelector((state) => state.partnerPreferences);
  const { Header, Content, Footer } = Layout;

  let { id } = useParams();
  const getPartnerPreferences = async () => {
    setLoading((loading) => {
      return { ...loading, get: true };
    });
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `preference`,
      });
      if (response?.response?.status == 200) {
        //console.log("---->>>>>>", response.data);
        let data = response.data.data;
        let obj = {
          minAge: data?.age?.min,
          maxAge: data?.age?.max,
          minHeight: data?.height?.min,
          maxHeight: data?.height?.max,
          minIncome: data?.income?.min,
          maxIncome: data?.income?.max,
          country: data?.country,
          residentialStatus: data?.residentialStatus,
          maritalStatus: data?.maritalStatus,
          occupation: data?.occupation,
          religion: data?.religion,
          caste: data?.cast,
          motherTongue: data?.motherTongue,
          education: data?.education,
          horoscope: data?.horoscope,
          manglik: data?.manglik,
        };
        //console.log("---->>>>>>obj", obj);
        //console.log(partnerPreferences);
        Object.keys(obj)?.forEach((item) => {
          handleFormChanges(item, obj[item]);
        });
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, get: false };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPartnerPreferences();
    if (!id) {
      updateLastScreenActivity("partnerpreference");
    }
  }, []);

  useEffect(() => {
    if (Object.keys(lookupData).length) {
      let data = [...fields];
      data = fields.map((field) => {
        return {
          ...field,
          defaultValueGetter: partnerPreferences,
          ...(field?.apiLookups === "country" && {
            options: countries,
          }),
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
          ...(field?.lookups?.length &&
            field?.nestedLookups?.length && {
              options: lookupData[field?.nestedLookups]?.map((item) => {
                return {
                  label: item.label,
                  options: lookupData[field?.lookups]?.filter(
                    (i) => i.religion === item.value
                  ),
                };
              }),
            }),
        };
      });
      setFields(data);

      let data1 = [...fields1];
      data1 = fields1.map((field) => {
        return {
          ...field,
          defaultValueGetter: partnerPreferences,
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      setFields1(data1);

      let data2 = [...fields2];
      data2 = fields2.map((field) => {
        return {
          ...field,
          defaultValueGetter: partnerPreferences,
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      setFields2(data2);

      let data3 = [...fields3];
      data3 = fields3.map((field) => {
        return {
          ...field,
          defaultValueGetter: partnerPreferences,
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      setFields3(data3);
    }
  }, [lookupData, countries, partnerPreferences]);

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
    dispatch(
      updatePartnerPreferences({
        key: name,
        value: value,
      })
    );
  };
  const addPartnerPreferences = async () => {
    setLoading((loading) => {
      return { ...loading, set: true };
    });
    try {
      let response = await AjaXpress({
        method: "PATCH",
        body: {
          country: partnerPreferences.country,
          residentialStatus: partnerPreferences.residentialStatus,
          occupation: partnerPreferences.occupation,
          maritalStatus: partnerPreferences.maritalStatus,
          religion: partnerPreferences.religion,
          cast: partnerPreferences.caste,
          education: partnerPreferences.education,
          horoscope: partnerPreferences.horoscope,
          motherTongue: partnerPreferences.motherTongue,
          manglik: partnerPreferences.manglik,
          age: {
            min: partnerPreferences.minAge,
            max: partnerPreferences.maxAge,
          },
          height: {
            min: partnerPreferences.minHeight,
            max: partnerPreferences.maxHeight,
          },
          income: {
            min: partnerPreferences.minIncome,
            max: partnerPreferences.maxIncome,
          },
        },
        slug: "preference",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);

        if (id) {
          navigate("/dashboard");
        } else {
          navigate("/underVerification");
        }
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, set: false };
    });
  };
  const handleNext = (values) => {
    addPartnerPreferences();
  };
  return (
    <Layout>
      {!!id && <NavBar navigate={navigate} />}
      <Layout>
        {!!id && <CustomSider navigate={navigate} />}
        <Content className="content-wrapper">
          <div>
            <Text className="midtitle"></Text>
          </div>
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="login-margin" style={{ marginBottom: "3rem" }}>
                {!id && <Progressbar percent={100} />}
                <div className="sub-title">Partner Preferences</div>
                <Form
                  layout="vertical"
                  name="loginForm"
                  form={form}
                  onFinish={handleNext}
                  initialValues={{
                    minAge: partnerPreferences.minAge,
                    maxAge: partnerPreferences.maxAge,
                    minHeight: partnerPreferences.minHeight,
                    maxHeight: partnerPreferences.maxHeight,
                    minIncome: partnerPreferences.minIncome,
                    maxIncome: partnerPreferences.maxIncome,
                    country: partnerPreferences.country,
                    residentialStatus: partnerPreferences.residentialStatus,
                    maritalStatus: partnerPreferences.maritalStatus,
                    occupation: partnerPreferences.occupation,
                    religion: partnerPreferences.religion,
                    caste: partnerPreferences.caste,
                    motherTongue: partnerPreferences.motherTongue,
                    education: partnerPreferences.education,
                    horoscope: partnerPreferences.horoscope,
                    manglik: partnerPreferences.manglik,
                  }}
                >
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      margin: "1rem 0rem",
                    }}
                  >
                    Age
                  </div>
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    {fields2?.map((Field, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Form.Item
                            label={Field.label}
                            name={Field.name}
                            rules={Field.rules}
                            style={Field.style ? Field.style : {}}
                            {...(Field.feedBack && {
                              hasFeedback: Field.feedBack,
                            })}
                            {...(Field.valuePropName && {
                              valuePropName: Field.valuePropName,
                            })}
                          >
                            {formFieldRenderer(Field, handleFormChanges)}
                          </Form.Item>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      margin: "1rem 0rem",
                    }}
                  >
                    Height
                  </div>
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    {fields1?.map((Field, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Form.Item
                            label={Field.label}
                            name={Field.name}
                            rules={Field.rules}
                            style={Field.style ? Field.style : {}}
                            {...(Field.feedBack && {
                              hasFeedback: Field.feedBack,
                            })}
                            {...(Field.valuePropName && {
                              valuePropName: Field.valuePropName,
                            })}
                          >
                            {formFieldRenderer(Field, handleFormChanges)}
                          </Form.Item>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      margin: "1rem 0rem",
                    }}
                  >
                    Income
                  </div>
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    {fields3?.map((Field, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Form.Item
                            label={Field.label}
                            name={Field.name}
                            rules={Field.rules}
                            style={Field.style ? Field.style : {}}
                            {...(Field.feedBack && {
                              hasFeedback: Field.feedBack,
                            })}
                            {...(Field.valuePropName && {
                              valuePropName: Field.valuePropName,
                            })}
                          >
                            {formFieldRenderer(Field, handleFormChanges)}
                          </Form.Item>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {fields?.map((Field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Item
                          label={Field.label}
                          name={Field.name}
                          rules={Field.rules}
                          style={Field.style ? Field.style : {}}
                          {...(Field.feedBack && {
                            hasFeedback: Field.feedBack,
                          })}
                          {...(Field.valuePropName && {
                            valuePropName: Field.valuePropName,
                          })}
                        >
                          {formFieldRenderer(Field, handleFormChanges)}
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="border-Radius-5px block-button-text height-100p"
                      loading={loading.set}
                    >
                      {id ? "Update" : "Next"}
                    </Button>
                  </Form.Item>
                </Form>
                {!id && (
                  <Button
                    type="text"
                    block
                    className="border-Radius-5px block-button-text height-100p back-button"
                    onClick={() => navigate("/familydetails")}
                  >
                    <CaretLeftOutlined />
                    Back
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SignupPartnerPreferences;
