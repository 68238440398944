import React, { useEffect, useState } from "react";
import { Col, Empty, Layout, Pagination, Row, Typography, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getInterestsRecv,
  getUnInterestsRecv,
  getallprofiles,
  getblockprofile,
  getignoreprofile,
  getshortlist,
  getunblockprofile,
  getunignoreprofile,
  getunshortlist,
  sendInterest,
  unsendInterest,
  updateInterest,
  updateNotificationCount,
} from "../helperFunctions/endpoints";
import {
  AllProfileUserCard,
  UserLargeListProfileCard,
} from "../genericComponents/UserProfileCard";
import { toast } from "react-toastify";
import { ProfileListScreen, ProfileNavScreen } from "../utils/utils";
import NavBar from "../genericComponents/CustomNav";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Header, Content, Footer } = Layout;

export const ProfilesList = () => {
  const { Text } = Typography;
  const [pageConfig, setPageConfig] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [allProfilesData, setAllProfilesData] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [isVisibleInterest, setIsVisibleInterest] = useState(true);
  const [isVisibleShortlist, setIsVisibleShortlist] = useState(true);
  const [isVisibleIgnore, setIsVisibleIgnore] = useState(true);
  const [isVisibleUnInterest, setIsVisibleUnSendInterest] = useState(false);
  const [isVisibleUpdateInterest, setIsVisibleUpdateInterest] = useState(false);
  const [isVisibleUnShortlist, setIsVisibleUnShortlist] = useState(false);
  const [isVisibleUnIgnore, setIsVisibleUnIgnore] = useState(false);
  const [screenName, setScreenName] = useState("default");
  const pageSize = 12; // Items per page

  const navigate = useNavigate();
  const { id } = useParams();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    window.scrollTo(0, 0);
    const page = ProfileListScreen[id];
    setPageConfig(page);
    getProfiles(page);
  }, [id]);

  const getProfiles = async (page) => {
    const res = await getallprofiles(page?.api);
    let profiles = res[page?.dataKey] || res;
    const notificationReadObj = {
      ids: [],
      type: "",
    };
    if (page?.api === "dashboard/getMyInterestedProfiles") {
      notificationReadObj.type = "interestSent";
      setScreenName("interestSent");
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isInterestSent: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getMyBlockedProfiles") {
      notificationReadObj.type = "blockedProfile";
      setScreenName("blockedProfile");
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isBlocked: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getAllIgnoredProfiles") {
      notificationReadObj.type = "ignoredProfile";
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isIgnored: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "interest/getInterests") {
      notificationReadObj.type = "interestReceived";
      setScreenName("interestReceived");
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isInterestReceived: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getMyDeclinedProfiles") {
      notificationReadObj.type = "iDeclined";
      setScreenName("iDeclined");
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isIDeclined: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getMyUnlockedProfiles") {
      notificationReadObj.type = "unlockedProfiles";
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, unlocked: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getMyShortlistedProfiles") {
      notificationReadObj.type = "shortlistedProfile";
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isShortlisted: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getUsersWhoHaveDeclinedMe") {
      notificationReadObj.type = "theyDeclined";
      setScreenName("theyDeclined");
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isTheyDeclined: true };
      });
      notificationReadObj.ids = ids;
    } else if (page?.api === "dashboard/getAcceptanceProfiles/acceptedMe") {
      setScreenName("acceptedMe");
      const ids = [];
      profiles = profiles.map((item) => {
        ids.push(item?.clientID);
        return { ...item, isInterestAcceptedMe: true };
      });
    }
    if (notificationReadObj?.ids?.length > 0 && notificationReadObj?.type) {
      await updateNotificationCount(notificationReadObj);
    }
    setAllProfilesData(profiles);
    // Set initial page data
    const startIndex = 0;
    const endIndex = pageSize;
    setFilteredProfiles(profiles.slice(startIndex, endIndex));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    
    // Calculate the correct slice indices
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    // Create a new slice of data for the current page
    const newPageData = allProfilesData.slice(startIndex, endIndex);
    setFilteredProfiles(newPageData);
  };

  const interestClick = (profileItem) => {
    if (profileItem?.isInterestSent) {
      unsendInterest({ receiver_id: profileItem?.clientID }).then(
        async (res) => {
          if (res?.code === "CH200") {
            await getProfiles(pageConfig);
            toast.success(res?.message);
          }
        }
      );
    } else {
      sendInterest({ targetId: profileItem?.clientID }).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    }
  };

  const shortlistClick = (profileItem) => {
    if (profileItem?.isShortlisted) {
      getunshortlist(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    } else {
      getshortlist(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    }
  };
  const updateInterestClick = (profileItem, status) => {
    const reqbody = {
      _id: profileItem?.clientID,
      status: status,
    };
    updateInterest(reqbody).then(async (res) => {
      if (res?.code === "CH200") {
        filteredProfiles.map((item) => {
          if (item?.clientID === profileItem?.clientID) {
            item.isInterestAcceptedMe = status === "accept" ? true : false;
          }
        });
        setFilteredProfiles([...filteredProfiles]);
        toast.success(res?.message);
      }
    });
  };

  const ignoreClick = (profileItem) => {
    if (profileItem?.isIgnored) {
      getunignoreprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    } else {
      getignoreprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    }
  };

  const blockClick = (profileItem) => {
    if (profileItem?.isBlocked) {
      getunblockprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    } else {
      getblockprofile(profileItem?.clientID).then(async (res) => {
        if (res?.code === "CH200") {
          await getProfiles(pageConfig);
          toast.success(res?.message);
        }
      });
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <div>
              <Row style={{ width: "auto" }}>
                <Col span={24}>
                  <Text className="midtitle">{pageConfig?.title}</Text>
                  <Row>
                    {filteredProfiles?.map((profile, index) => (
                      <Col key={profile.clientID} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <AllProfileUserCard
                          navigate={navigate}
                          profileItem={profile}
                          shortlistClick={() => shortlistClick(profile)}
                          interestClick={() => interestClick(profile)}
                          ignoreClick={() => ignoreClick(profile)}
                          blockClick={() => blockClick(profile)}
                          acceptInterestClick={updateInterestClick}
                          declineInterestClick={updateInterestClick}
                          screenName={screenName}
                        />
                      </Col>
                    ))}
                  </Row>

                  {allProfilesData?.length > 0 && (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <Pagination
                        current={currentPage}
                        total={allProfilesData.length}
                        pageSize={pageSize}
                        showSizeChanger={false}
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              {filteredProfiles?.length === 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Profiles Found"
                  />
                </div>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
